import xss from 'xss'
import { notifyAnalytics } from './PersonalizedButton.js'

const menuClasses = {
  ulClass: [
    'List List--selectable amp-childNav u-bgColorWhite u-sm-paddingTop0 u-sm-paddingBottom0 u-md-paddingTop0 u-md-paddingBottom0 u-paddingLeft0',
    'List List--selectable amp-grandchildNav u-bgColorWhite u-sm-paddingTop0 u-sm-paddingBottom0 u-md-paddingTop0 u-md-paddingBottom0 u-paddingLeft0'
  ],
  liClass: [
    'List-item amp-mainNav-item u-marginHoriz4gu u-md-marginHoriz0 u-sm-marginHoriz0',
    'List-item amp-childNav-item u-block',
    'List-item amp-grandchildNav-item u-block'
  ],
  liHasSubitemsClass: [
    ' amp-mainNav-item--hasDropdown',
    ' amp-childNav-item--hasDropdown'
  ],
  liDiv: [
    'amp-mainNav-link-wrapper  u-sm-marginVert0 u-md-marginVert0',
    'amp-childNav-link-wrapper',
    'amp-grandchildNav-link-wrapper'
  ],
  aClass: [
    'Link Icon--after amp-mainNav-link u-paddingHoriz0 u-paddingVert2gu u-sm-paddingHoriz3gu u-sm-paddingVert3gu u-md-paddingHoriz3gu u-md-paddingVert3gu u-sm-textSizeMsrm1 u-md-textSizeMsrm1 u-textNoWrap',
    'Link amp-childNav-link u-textNoWrap u-paddingVert3gu u-paddingHoriz3gu u-sm-paddingLeft8gu u-sm-paddingRight3gu u-sm-textSizeMsrm1 u-md-textSizeMsrm1',
    'Link amp-grandchildNav-link u-textNoWrap u-paddingVert3gu u-paddingHoriz3gu u-sm-paddingRight3gu u-sm-paddingLeft10gu u-md-paddingRight3gu u-md-paddingLeft10gu u-sm-textSizeMsrm1 u-md-textSizeMsrm1'
  ],
  aHasSubitemsClass: [
    'Link Icon--after amp-mainNav-link u-paddingHoriz0 u-paddingVert2gu u-sm-paddingHoriz3gu u-sm-paddingVert3gu u-md-paddingHoriz3gu u-md-paddingVert3gu u-sm-textSizeMsrm1 u-md-textSizeMsrm1 u-textNoWrap',
    'Link amp-childNav-link u-textNoWrap Icon--after amp-childNav-link--hasDropdown u-paddingVert3gu u-paddingLeft3gu u-sm-paddingLeft8gu u-sm-paddingRight3gu u-md-paddingLeft8gu u-md-paddingRight3gu u-sm-textSizeMsrm1 u-md-textSizeMsrm1 u-md-textSizeMsrm1'
  ],
  subnavLiClass: [
    'List List--selectable amp-childNav u-bgColorWhite u-paddingBottom2gu u-sm-paddingTop0 u-sm-paddingBottom0 u-md-paddingTop0 u-md-paddingBottom0 u-decoRoundedCorners',
    'List List--selectable amp-grandchildNav u-bgColorWhite u-paddingTop2gu u-paddingBottom2gu u-sm-paddingTop0 u-sm-paddingBottom0 u-md-paddingTop0 u-md-paddingBottom0 u-decoRoundedCorners'
  ],
  subnavAria: ['submenu', 'submenu'],
  subnavDataQA: ['MainNavLevelTwo', 'MainNavLevelThree']
}

export const addMenuItem = (
  listElt,
  menuItem,
  menuDepth,
  isFirst,
  parentName
) => {
  const menuLi = document.createElement('li')
  const hasSubItems =
    menuItem.menuItems && menuItem.menuItems.length > 0 && menuDepth < 2 // never go deeper than tertiary
  menuLi.className =
    menuClasses.liClass[menuDepth] +
    (hasSubItems ? menuClasses.liHasSubitemsClass[menuDepth] : '')
  const hasCustomAnalytics = menuItem.analyticsType && menuItem.analyticsName
  menuLi.tabIndex = -1
  menuLi.setAttribute('role', 'group')
  const menuDiv = document.createElement('div')
  menuDiv.className = menuClasses.liDiv[menuDepth]
  menuLi.appendChild(menuDiv)
  const menuAnchor = document.createElement('a')
  menuAnchor.tabIndex = 0
  menuAnchor.className = hasSubItems
    ? menuClasses.aHasSubitemsClass[menuDepth]
    : menuClasses.aClass[menuDepth]
  menuAnchor.href = xss(menuItem.url)
  menuAnchor.setAttribute('role', hasSubItems ? 'menuitem' : 'link')
  if (hasSubItems) menuAnchor.setAttribute('aria-expanded', 'false')
  const analyticsName = parentName
    ? parentName + ' | ' + menuItem.name
    : menuItem.name
  if (hasCustomAnalytics) {
    menuAnchor.addEventListener(
      'click',
      notifyAnalytics.bind(
        undefined,
        menuItem.analyticsType,
        menuItem.analyticsName
      )
    )
  } else {
    menuAnchor.setAttribute('data-analytics', analyticsName)
  }
  const dataIcon = document
    .querySelector('ameriprise-menu')
    .getAttribute('data-icon')
  menuAnchor.setAttribute('data-icon', dataIcon)
  menuAnchor.setAttribute('aria-haspopup', hasSubItems ? 'true' : 'false')
  if (menuItem.classes) menuLi.classList.add(menuItem.classes)
  if (menuItem.name === 'Home') {
    const img = document.createElement('img')
    img.className = 'amp-home-link-img'
    const logo = document
      .querySelector('ameriprise-menu')
      .getAttribute('data-logo')
    img.src = xss(logo)
    menuAnchor.appendChild(img)
    menuLi.classList.remove('u-marginHoriz4gu')
    menuLi.classList.add('amp-home', 'u-marginLeft0', 'u-marginRight4gu')
  }

  if (menuDepth > 0) {
    menuAnchor.innerHTML = xss(menuItem.name)
  } else {
    const aspan = document.createElement('span')
    aspan.innerText = xss(menuItem.name)
    aspan.classList = menuItem.name === 'Home' ? 'amp-home-link' : ''
    menuAnchor.appendChild(aspan)
  }
  menuAnchor.setAttribute('aria-label', menuItem.name)
  menuDiv.appendChild(menuAnchor)

  if (hasSubItems) {
    const subMenu = document.createElement('ul')
    subMenu.setAttribute('role', 'menu')
    subMenu.className = menuClasses.ulClass[menuDepth]
    subMenu.setAttribute('aria-label', menuClasses.subnavAria[menuDepth])
    subMenu.setAttribute('data-qa', menuClasses.subnavDataQA[menuDepth])
    menuLi.appendChild(subMenu)
    if (menuDepth === 1) {
      addMenuItem(
        subMenu,
        generateHomeElt(menuItem).HstMenuItemRepresentation,
        menuDepth + 1,
        true,
        analyticsName
      )
    }
    for (let i = 0; menuItem.menuItems && i < menuItem.menuItems.length; i++) {
      addMenuItem(
        subMenu,
        menuItem.menuItems[i].HstMenuItemRepresentation,
        menuDepth + 1,
        menuDepth !== 1 && i === 0,
        analyticsName
      )
    }
  }
  listElt.appendChild(menuLi)
}

function generateHomeElt(menuJson) {
  return {
    HstMenuItemRepresentation: {
      name: menuJson.name + ' Home',
      url: menuJson.url,
      classes: 'u-lg-hidden'
    }
  }
}
