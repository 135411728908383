import jsCookie from 'js-cookie'

export default class AmeripriseLoaderElement extends HTMLElement {
  cacheDurationInMilliseconds = 2 * 60 * 1000 // 2 mins

  constructor() {
    super()

    this.retries = 0
    this.lastAmpAuthState = jsCookie.get('lastAmpAuthState')
    this.checkCookie = this.checkCookie.bind(this)
  }

  generateCacheId(timestamp) {
    const currentTimestamp = timestamp || Date.now()
    const cacheId =
      Math.floor(currentTimestamp / this.cacheDurationInMilliseconds) *
      this.cacheDurationInMilliseconds

    return cacheId.toString()
  }

  getComponentUrl(siteUrl, componentName, timestamp) {
    const cacheId = this.generateCacheId(timestamp)

    const siteUrlEncoded = encodeURI(siteUrl)

    const pageHeader = `/shared-nav./header/main-nav?siteurl=${siteUrlEncoded}&v=${cacheId}`
    const pageFooter = `/shared-nav./footer/footer-nav?siteurl=${siteUrlEncoded}&v=${cacheId}`

    // Any Ampcom path will work for these, we can guarantee render by getting a 404
    const page404Header = `/404./header/main-nav?siteurl=${siteUrlEncoded}&v=${cacheId}`
    const page404Footer = `/404./footer/footer-nav?siteurl=${siteUrlEncoded}&v=${cacheId}`

    if (siteUrl && componentName) {
      switch (componentName) {
      case 'header':
        return siteUrl + pageHeader
      case 'footer':
        return siteUrl + pageFooter
      default:
        return siteUrl + pageHeader
      }
    }

    if (siteUrl && !componentName) {
      return siteUrl + pageHeader
    }

    if (!siteUrl && componentName) {
      switch (componentName) {
      case 'header':
        return page404Header
      case 'footer':
        return page404Footer
      default:
        return page404Header
      }
    }

    return page404Header
  }

  async fetchInnerHtml(siteUrl, componentName) {
    const componentUrl = this.getComponentUrl(siteUrl, componentName)

    if (!componentUrl) {
      return
    }

    return fetch(componentUrl, { credentials: 'include' })
      .then(result => result.text())
      .then(result => {
        if (!result || result.length < 1) {
          return Promise.reject(new Error('No results found'))
        }

        // Remove ios-banner element from shared nav
        const formattedHtml = result
          .replace(/<script[^>]*>(?:(?!<\/script>)[^])*<\/script>/g, '')
          .replace(
            /<ios-banner[^>]*>(?:(?!<\/ios-banner>)[^])*<\/ios-banner>/g,
            ''
          )

        return Promise.resolve(formattedHtml)
      })
      .catch(err => {
        console.error(err)
      })
  }

  async checkCookie(siteUrl, componentName) {
    this.retries++

    const lastAmpAuthState = jsCookie.get('lastAmpAuthState')

    if (lastAmpAuthState !== this.lastAmpAuthState) {
      this.innerHTML = await this.fetchInnerHtml(siteUrl, componentName)

      window.clearInterval(this.interval)
    } else if (this.retries > 20) {
      window.clearInterval(this.interval)
    }
  }

  async loadData(siteUrl, componentName) {
    this.innerHTML = await this.fetchInnerHtml(siteUrl, componentName)

    if (componentName === 'header') {
      this.interval = window.setInterval(
        await this.checkCookie(siteUrl, componentName),
        250
      )
    }
  }

  connectedCallback() {
    /* make up for missing amp-scope class at root of main site */
    const docBody = document.querySelector('body')
    if (!docBody.classList.contains('amp-scope')) {
      docBody.style.position = 'relative'
    }

    const siteUrl = this.getAttribute('data-url')
    const componentName = this.getAttribute('data-component')

    this.loadData(siteUrl, componentName)
  }
}
