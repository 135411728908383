import jsCookie from 'js-cookie'

import { getUser } from './../../../services'

import Analytics from '../../SiteNav/analytics.js'
import Menubar from './menubar.js'
import dynamicAddMenus from './dynamicmenus.js'
import PersonalizedMobileButton from './PersonalizedMobileButton'
import { PersonalizedButtonJson } from '../Utility/PersonalizedButton'

import './navigation.css'
import { addMenuItem } from '../Utility/addMenuItem'

function IsLargeViewport () {
  return (
    (window.innerWidth * 16) /
      parseFloat(
        getComputedStyle(document.querySelector('html'))['font-size']
      ) >
    1024
  )
}

const CONSTs = {
  PRODUCTION: 'production',
  QA: 'qa',
  DEV: 'dev',
  LOCAL: 'local',
  PREVIEW: 'preview',
  SISTERSITE: 'sisterSite',
  UNKNOWN: 'unknown',
  ERRORMESSAGE: 'Please enter a search term',
  POPOVER_CLASS: 'Popover',
  IS_VISIBLE_CLASS: 'is-visible'
}

export const isDomainAllowed = () => {
  const host = window.location.hostname
  if (
    /localhost/.test(host) ||
    /ipp\.ameriprise\.com/.test(host) ||
    /ipp\.qa\.ameriprise\.com/.test(host) ||
    /qa\.ameripriseadvisors\.com/.test(host) ||
    /ameripriseadvisors\.com/.test(host) ||
    /newsroom\.ameriprise\.com/.test(host) ||
    /newsroom\.qa\.ameriprise\.com/.test(host)
  ) {
    return false
  } else {
    return true
  }
}

var ua = window.navigator.userAgent.toLowerCase()

// IE user-agent strings contain "MSIE" and/or "Trident" (code name for IE's rendering engine)
const _isIE = ua.indexOf('msie') !== -1 || ua.indexOf('trident') !== -1

/*
 * Ameriprise Site Navigation
 */
export class AmeripriseMenu extends HTMLElement {
  constructor () {
    super()
    this.cmsMenu = document
      .querySelector('ameriprise-menu')
      .getAttribute('menu-name')
    this.userTemplate = ''
    this.shadow = this.attachShadow({ mode: 'open' })
    this.currentHostName = window.location.hostname
    this.currentPort = window.location.port
    this.currentURL = window.location.pathname
    this.currentEnvironment = this.getEnvironment()

    this.selectedNav = this.getSelectedTab()
  }

  getEnvironment () {
    const isLocal =
      /localhost|127\.0\.0\.1/i.test(this.currentHostName) ||
      this.currentPort === '8080'
    const isAmeriprisePublicSite =
      /(www|www1)(\d*|-ws1|preview|demo)?(\.qa.stage|\.qa|.dev|)?\.ameriprise\.com/.test(
        this.currentHostName
      )
    const isAnyLower =
      /dev|ws1.dev|qa|ws1.qa|qa.stage|preview|127\.0\.0\.1|local/i.test(
        this.currentHostName
      ) || this.currentPort === '8080'
    const isAmpLower = isAmeriprisePublicSite && isAnyLower
    const isProd = !isAnyLower && !isAmpLower
    const isSisterSite = !isAmeriprisePublicSite && !isLocal
    const isDev = /dev|ws1.dev\./i.test(this.currentHostName)
    const isQa = /qa|ws1.qa\./i.test(this.currentHostName)
    const isPreview = /preview\./i.test(this.currentHostName)

    switch (true) {
    case isProd:
      return CONSTs.PRODUCTION
    case isLocal:
      return CONSTs.LOCAL
    case isQa:
      return CONSTs.QA
    case isDev:
      return CONSTs.DEV
    case isSisterSite:
      return CONSTs.SISTERSITE
    case isPreview:
      return CONSTs.PREVIEW
    default:
      return CONSTs.UNKNOWN
    }
  }

  getSelectedTab () {
    let selectedNav = 'Home'
    let combinedURLValues = []
    const siteNavMenuConfig = document.querySelector(
      '#ampSiteNavMenuContainer'
    )
    if (siteNavMenuConfig && siteNavMenuConfig.innerText) {
      const siteNavMenuSettings = JSON.parse(siteNavMenuConfig.innerText)
      window.AMPSITENAVJSON = siteNavMenuSettings.AMPSITENAVJSON
      siteNavMenuSettings.AMPSITENAVJSON &&
        siteNavMenuSettings.AMPSITENAVJSON.forEach(item => {
          combinedURLValues.push(item)
        })
    } else {
      combinedURLValues = [
        { key: 'financial-planning', label: 'What We Do' },
        { key: 'products', label: 'Products' },
        { key: 'retirement', label: 'Retirement & Life events' },
        { key: 'research-market-insights', label: 'Research & Insights' }
      ]
    }

    if (
      this.currentURL &&
      this.currentEnvironment !== CONSTs.UNKNOWN &&
      this.currentEnvironment !== CONSTs.PREVIEW
    ) {
      const currentURLSplit = this.currentURL.split('/')

      if (
        currentURLSplit &&
        currentURLSplit.length &&
        currentURLSplit.length > 1
      ) {
        const currentLevelOneMenuFromURL =
          currentURLSplit[this.currentEnvironment !== CONSTs.LOCAL ? 1 : 3]
        if (
          currentLevelOneMenuFromURL &&
          currentLevelOneMenuFromURL.length > 1
        ) {
          combinedURLValues.map(item => {
            if (item.key === currentLevelOneMenuFromURL) {
              selectedNav = item.label
            }
          })
        }
      }
    }

    return selectedNav
  }

  setCurrentNav (
    selectedNav,
    menuLevel,
    navLevel,
    menuLevelNext,
    navLevelNext,
    firstTime
  ) {
    const this1 = this

    const levelClass =
      menuLevel > 1 ? 'nav.amp-nav-level-two' : 'nav.amp-nav-level-one'
    const navLevelClass =
      navLevel > 1 ? '.amp-childNav-item' : '.amp-mainNav-item'

    const navItems = this.shadow.querySelectorAll(
      '.amp-nav-inner' +
        ' ' +
        levelClass +
        ' ' +
        '.amp-mainNav' +
        ' ' +
        navLevelClass
    )
    var focusItem = null
    if (navItems) {
      Array.from(navItems).forEach(navItem => {
        const navLinkClass =
          menuLevel > 2 ? '.amp-childNav-link' : '.amp-mainNav-link span'
        const navLink = navItem.querySelector(navLinkClass)

        if (navLink) {
          const navItemClass =
            menuLevel > 2
              ? 'amp-childNav-item--current'
              : 'amp-mainNav-item--current'

          if (
            navLink.textContent &&
            selectedNav &&
            navLink.textContent.toLowerCase() === selectedNav.toLowerCase() &&
            !navItem.classList.contains(navItemClass)
          ) {
            navItem.classList.add(navItemClass)
            navItem.classList.add('is-selected')
            focusItem = navItem.querySelector('a')
          } else {
            navItem.classList.remove(navItemClass)
            navItem.classList.remove('is-selected')
          }

          if (firstTime) {
            this.setupNavEvents(
              navItem,
              this1,
              navLink,
              menuLevelNext,
              navLevelNext,
              menuLevel
            )
          }
        }
      })

      if (firstTime && menuLevel > 1) {
        // Back button
        const navLinkBack = this.shadow.querySelector(
          '.amp-nav-level-two .amp-mainNav-backButton'
        )

        navLinkBack &&
          navLinkBack.addEventListener('click', event =>
            this.mobileBackClick(event)
          )
        navLinkBack &&
          navLinkBack.addEventListener('keydown', event =>
            this.mobileBackKeydown(event)
          )
      }

      if (!firstTime && menuLevel > 1) {
        this.navMenu.classList.remove('amp-nav-level-one')
        this.navMenu.classList.add('amp-nav-level-two')
      } else {
        this.navMenu.classList.remove('amp-nav-level-two')
        this.navMenu.classList.add('amp-nav-level-one')
        this.navMenu.classList.remove('amp-nav-search')

        if (this.search) {
          Array.from(this.search).forEach(searchItem2 => {
            searchItem2.classList.remove('amp-mainNav-item--current')
            searchItem2.classList.remove('is-selected')
          })
        }
      }
      // mobile on menu reset focus on the active item
      !firstTime && focusItem && focusItem.focus()
    }
  }

  setupNavEvents (
    navItem,
    this1,
    navLink,
    menuLevelNext,
    navLevelNext,
    menuLevel
  ) {
    if (
      navItem.classList.contains('amp-mainNav-item--hasDropdown') ||
      navItem.classList.contains('amp-childNav-item--hasDropdown')
    ) {
      /* IE HACK BEGIN */
      /* Could not get preventDefault() (below) to work on A tags in IE */
      if (_isIE) {
        this.navEventsIEHack(navItem, this1, menuLevel)
      }
      /* IE HACK END */

      // Nav buttons
      navItem.addEventListener('click', event => {
        this.mobileClick(event, this1, navLink, menuLevelNext, navLevelNext)
      })

      navItem.addEventListener('keydown', event => {
        if (event.keyCode === 13 || event.keyCode === 32) {
          this.mobileClick(event, this1, navLink, menuLevelNext, navLevelNext)
        }
      })
    }
  }

  navEventsIEHack (navItem, this1, menuLevel) {
    const navLinkClass2 =
      menuLevel > 2 ? '.amp-childNav-link' : '.amp-mainNav-link'
    const navLink2 = navItem.querySelector(navLinkClass2)

    const navLinkHref = navLink2.getAttribute('href')

    navLink2.setAttribute('href', '')

    navLink2.addEventListener('click', event => {
      if (!this1.navMenu.classList.contains(CONSTs.POPOVER_CLASS)) {
        event.preventDefault()
        event.stopPropagation()

        window.location = navLinkHref
      }
    })
  }

  mobileBackClick (event) {
    event.preventDefault()
    event.stopPropagation()
    this.resetCurrentNav()
    this.menubar.setFocus()
  }

  mobileBackKeydown (event) {
    if (event.keyCode === 13 || event.keyCode === 32) {
      this.mobileBackClick(event)
    }
  }

  mobileClick (event, this1, navLink, menuLevelNext, navLevelNext) {
    // Only do this click event if we're in "hamburger menu"
    if (this1.navMenu.classList.contains(CONSTs.POPOVER_CLASS)) {
      if (event.target.classList.contains('amp-grandchildNav-link')) {
        return
      }

      event.preventDefault()
      event.stopPropagation()
      if (
        event.target ===
        this.shadow.querySelector('.amp-nav-level-two .amp-mainNav-backButton')
      ) {
        this.mobileBackClick(event)
      } else {
        this1.setCurrentNav(navLink.textContent, menuLevelNext, navLevelNext)
      }
    }
  }

  resetCurrentNav () {
    this.setCurrentNav('', 3, 2)
    this.setCurrentNav('', 2, 1)
    this.setCurrentNav('', 1, 2)
  }

  updateMenuIcon (popOverState) {
    // Update the menu icon and document body overflow based on the popover state
    const docBody = document.querySelector('body')
    if (popOverState === 'open') {
      /* eslint-disable-next-line no-unused-expressions */
      this.iconContainerElement?.classList.add('menu-open')
      docBody.style.overflow = 'hidden'
    } else {
      /* eslint-disable-next-line no-unused-expressions */
      this.iconContainerElement?.classList.remove('menu-open')
      docBody.style.overflow = 'auto'
    }
  }

  resized (firstTime) {
    // Add `Popover` class to Nav if small or medium viewport, otherwise remove that class and `is-visible`
    if (IsLargeViewport()) {
      this.navMenu.classList.remove(CONSTs.POPOVER_CLASS)
      this.navMenu.classList.remove(CONSTs.IS_VISIBLE_CLASS)
      this.updateMenuIcon('close')
    } else {
      let reset = false

      if (
        !firstTime &&
        !this.navMenu.classList.contains(CONSTs.POPOVER_CLASS)
      ) {
        reset = true
      }

      this.navMenu.classList.add(CONSTs.POPOVER_CLASS)

      if (reset) {
        this.resetCurrentNav()
      }

      this.nav.classList.remove('sticky')
    }
    this.menubar.setMode(IsLargeViewport())
  }

  clicked (target) {
    this.iconContainerElement = target.querySelector('#main-menu-icon-wrapper')
    if (this.navMenu.classList.contains(CONSTs.IS_VISIBLE_CLASS)) {
      this.navMenu.classList.remove(CONSTs.IS_VISIBLE_CLASS)
      this.resetCurrentNav()
      this.updateMenuIcon('close')
    } else {
      this.navMenu.classList.add(CONSTs.IS_VISIBLE_CLASS)
      this.updateMenuIcon('open')
      this.menubar.setFocus()
    }
  }

  connectedCallback () {
    const menuType = 'main-menu-'
    const objName = 'ameriprise-menu'
    const defaultMenu = 'main-menu-prospect'
    const createMenusFromJsonDataFn = this.createMenusFromJsonData.bind(this)
    this.template = this.querySelector('#main-nav-template')

    if (this.template && this.template.content) {
      this.shadow.appendChild(this.template.content)
      this.userTemplate = this.querySelector('#main-nav-prospect-template')
      this.nav = this.shadow.querySelector('.amp-nav')
      const isServiceCallAllowed = isDomainAllowed()
      if (
        isServiceCallAllowed &&
        !this.cmsMenu &&
        window.authState &&
        typeof window.authState.getUserStatus === 'function'
      ) {
        window.authState.getUserStatus().then(currentAuthStatus => {
          dynamicAddMenus(
            this.cmsMenu,
            currentAuthStatus,
            menuType,
            objName,
            defaultMenu,
            createMenusFromJsonDataFn
          )
        })
      } else {
        dynamicAddMenus(
          this.cmsMenu,
          jsCookie.get('lastAmpAuthState'),
          menuType,
          objName,
          defaultMenu,
          createMenusFromJsonDataFn
        )
      }
    }
  }

  createMenusFromJsonData (data) {
    const listElts = this.shadow.querySelectorAll(
      'nav.amp-nav-level-two ul.amp-mainNav,nav.amp-nav-level-one ul.amp-mainNav'
    )
    for (const listElt of listElts) {
      if (data.menuItems.length > 0) {
        this.addBackButton(listElt)
      }
      for (let i = 0; i < data.menuItems.length; i++) {
        addMenuItem(
          listElt,
          data.menuItems[i].HstMenuItemRepresentation,
          0,
          i === 0,
          ''
        )
      }
    }
    this.setupTemplate()
    this.mainSetup()
  }

  addBackButton (listElt) {
    const menuLi = document.createElement('li')
    menuLi.className =
      'List-item amp-mainNav-item amp-mainNav-backButton u-lg-hidden'
    const menuDiv = document.createElement('div')
    menuDiv.className = 'amp-mainNav-link-wrapper'
    menuLi.appendChild(menuDiv)
    const menuAnchor = document.createElement('a')
    menuAnchor.className =
      'Icon--before amp-mainNav-link u-sm-paddingVert3gu u-md-paddingVert3gu u-sm-textSizeMsrm1 u-md-textSizeMsrm1'
    menuAnchor.setAttribute(
      'data-icon',
      document.querySelector('ameriprise-menu').getAttribute('back-icon')
    )
    menuAnchor.innerHTML = 'Back'
    menuAnchor.tabIndex = '0'
    menuDiv.appendChild(menuAnchor)
    listElt.appendChild(menuLi)
  }

  setupTemplate () {
    if (
      window.authState &&
      typeof window.authState.getUserStatus === 'function'
    ) {
      window.authState.getUserStatus().then(currentAuthStatus => {
        switch (currentAuthStatus) {
        case 'active':
          this.userTemplate = this.querySelector('#main-nav-active-template')
          // If user is logged in, retrieve the user's name for display
          this.awaitInitClient()
          break
        case 'expired':
          this.userTemplate = this.querySelector(
            '#main-nav-expired-template'
          )
          break
        default:
          this.userTemplate = this.querySelector(
            '#main-nav-prospect-template'
          )
          this.awaitInitPersonalization()
          break
        }
        this.utilityNav = this.shadow.querySelectorAll('.amp-utilityNav')
        this.utilityNav[0].appendChild(
          this.userTemplate.content.cloneNode(true)
        )
        this.utilityNav[1].appendChild(
          this.userTemplate.content.cloneNode(true)
        )
        this.setupSearch()
      })
    } else {
      // To add search to sister sites where authentication is undefined
      this.userTemplate = this.querySelector('#main-nav-prospect-template')
      this.utilityNav = this.shadow.querySelectorAll('.amp-utilityNav')
      this.utilityNav[0].appendChild(this.userTemplate.content.cloneNode(true))
      this.utilityNav[1].appendChild(this.userTemplate.content.cloneNode(true))
      this.setupSearch()
      if (isDomainAllowed() || window.location.hostname === 'localhost') {
        this.awaitInitPersonalization()
      }
    }
  }

  mainSetup () {
    this.navMenu = this.shadow.querySelector('.amp-nav-inner')

    // Initial load of page: display "underline" on nav item for current page and set up mobile menu
    this.setCurrentNav(this.selectedNav, 1, 1, 2, 1, true)

    this.setCurrentNav('', 3, 2, 3, 2, true)

    this.menubar = new Menubar(this.nav, this.updateMenuIcon.bind(this)) // keyboard navigation
    this.menubar.init(document.querySelector('ameriprise-menu'))
    this.menubar.initBackClick(this.mobileBackClick.bind(this))

    // Initial load of page - display "hamburger menu" if small/medium viewport
    this.resized(true)

    this.setupMainEventHandlers()

    this.setupAnalyticsMenu()
    this.setupAnalyticsStickyNav()
  }

  setupSearch () {
    this.search = this.shadow.querySelectorAll('.amp-search-item')
    this.searchForm = this.shadow.querySelector(
      'nav.amp-nav-level-one .amp-searchForm'
    )
    this.searchFormMobile = this.shadow.querySelector(
      'nav.amp-nav-level-two .amp-searchForm'
    )
    this.searchInput = this.shadow.querySelector(
      'nav.amp-nav-level-one .amp-search-input'
    )
    this.searchInputMobile = this.shadow.querySelector(
      'nav.amp-nav-level-two .amp-search-input'
    )
    this.searchCloseIcon = this.shadow.querySelector(
      'nav.amp-nav-level-one .amp-close-search-icon'
    )
    this.searchCloseIconMobile = this.shadow.querySelector(
      'nav.amp-nav-level-two .amp-close-search-icon'
    )

    if (this.search && this.search.length > 0) {
      document.addEventListener('click', event => {
        if (event.target.tagName !== 'AMERIPRISE-MENU') {
          this.closeSearch()
        }
      })

      Array.from(this.search).forEach(searchItem => {
        searchItem.addEventListener('click', event => {
          if (this.navMenu.classList.contains('amp-nav-search')) {
            this.closeSearch(event)
          } else {
            this.searchButtonClicked(event)
          }
        })
        searchItem.addEventListener('keydown', event => {
          if (event.keyCode === 13 || event.keyCode === 32) {
            this.searchButtonClicked(event)
          }
          if (
            event.keyCode === 27 &&
            this.navMenu.classList.contains('amp-nav-search')
          ) {
            this.closeSearch(event)
          }
        })
      })

      const searchForms = [this.searchForm, this.searchFormMobile]

      searchForms.forEach(searchForm => {
        // desktop tab out happens when we focus out
        searchForm.addEventListener('keydown', event => {
          if (event.keyCode === 27) {
            this.closeSearch()
          }
        })

        searchForm.addEventListener('submit', event => {
          event.preventDefault()

          if (!IsLargeViewport()) {
            this.searchInput = this.searchInputMobile
          }

          // Submit search or show error
          if (
            this.searchInput &&
            this.searchInput.value &&
            this.searchInput.value.length > 0
          ) {
            // Analytics Tagging
            const searchMessage = this.searchInput.value
            const type = 'search'
            try {
              const searchAnalytics = new Analytics()
              searchAnalytics.ping(searchMessage, type)
            } catch (e) {}
            searchForm.submit()
          } else {
            this.searchInput.setAttribute('placeholder', CONSTs.ERRORMESSAGE)
          }
        })
      })

      if (!IsLargeViewport()) {
        this.searchCloseIcon = this.searchCloseIconMobile
      }

      this.searchCloseIcon.addEventListener('click', event => {
        this.closeSearch()
      })
      this.searchCloseIcon.addEventListener('keydown', event => {
        if (
          event.keyCode === 9 ||
          event.keyCode === 13 ||
          event.keyCode === 27
        ) {
          this.closeSearch()
        }
      })
    }
  }

  closeSearch (event) {
    document.querySelector('body').style.overflowY = ''
    this.shadow.querySelector('.amp-overlay').style.display = 'none'

    const selectedNavItem = document
      .querySelector('ameriprise-menu')
      .shadowRoot.querySelector('.is-selected')
    selectedNavItem.classList.add('amp-mainNav-item--current')

    // Close Nav menu if clicking "off"
    this.navMenu.classList.remove(CONSTs.IS_VISIBLE_CLASS)
    this.updateMenuIcon('close')
    // Reset Nav menu to "level one"
    this.resetCurrentNav()
    // Close Search window if clicking "off"

    this.navMenu.classList.remove('amp-nav-search')
    this.hideBox()

    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
  }

  searchButtonClicked (event) {
    let resultBox = this.shadow.querySelector(
      'nav.amp-nav-level-one .search-result-box'
    )
    let resultBoxMobile = this.shadow.querySelector(
      'nav.amp-nav-level-two .search-result-box'
    )
    let searchListCloseIcon = this.shadow.querySelector(
      'nav.amp-nav-level-one .search-list-close-icon'
    )
    let searchListCloseIconMobile = this.shadow.querySelector(
      'nav.amp-nav-level-two .search-list-close-icon'
    )
    let overlay = this.shadow.querySelector('.amp-overlay')
    let navItems = document
      .querySelector('ameriprise-menu')
      .shadowRoot.querySelectorAll(
        '.amp-nav-inner nav.amp-nav-level-one .amp-mainNav .amp-mainNav-item'
      )
    Array.from(navItems).forEach(navItem => {
      navItem.classList.remove('amp-mainNav-item--current')
    })
    document.querySelector('body').style.overflowY = 'hidden'
    if (IsLargeViewport()) {
      overlay.style.display = 'block'
    }

    event.preventDefault()
    event.stopPropagation()

    Array.from(this.search).forEach(searchItem2 => {
      searchItem2.classList.toggle('is-selected')
      searchItem2.classList.toggle('amp-mainNav-item--current')
    })
    // Open or close Search window if clicking Search button
    this.navMenu.classList.toggle('amp-nav-search')

    if (this.navMenu.classList.contains('amp-nav-search')) {
      // search active set focus to search input
      if (!IsLargeViewport()) {
        this.setCurrentNav('', 2, 1)
        this.searchInputMobile.focus()
      } else {
        this.searchInput.focus()
      }

      if (!IsLargeViewport()) {
        resultBox = resultBoxMobile
        searchListCloseIcon = searchListCloseIconMobile
      }

      resultBox.style.display = 'none'
      searchListCloseIcon.style.display = 'none'
      this.autocomplete()
    } else {
      // search closed set focus to search button
      this.search[0].focus()
    }
  }

  autocomplete () {
    let searchForm = this.shadow.querySelector(
      'nav.amp-nav-level-one .amp-searchForm'
    )
    let searchFormMobile = this.shadow.querySelector(
      'nav.amp-nav-level-two .amp-searchForm'
    )
    let searchInput = this.shadow.querySelector(
      'nav.amp-nav-level-one .amp-search-input'
    )
    let searchInputMobile = this.shadow.querySelector(
      'nav.amp-nav-level-two .amp-search-input'
    )
    let resultBox = this.shadow.querySelector(
      'nav.amp-nav-level-one .search-result-box'
    )
    let resultBoxMobile = this.shadow.querySelector(
      'nav.amp-nav-level-two .search-result-box'
    )
    let searchListCloseIcon = this.shadow.querySelector(
      'nav.amp-nav-level-one .search-list-close-icon'
    )
    let searchListCloseIconMobile = this.shadow.querySelector(
      'nav.amp-nav-level-two .search-list-close-icon'
    )
    let searchButton = this.shadow.querySelector('.amp-searchSubmit')
    let response = []
    let currentFocus
    let hideBox = this.hideBox
    let showSuggestions = this.showSuggestions

    if (!IsLargeViewport()) {
      searchForm = searchFormMobile
      searchInput = searchInputMobile
      resultBox = resultBoxMobile
      searchListCloseIcon = searchListCloseIconMobile
    }
    searchListCloseIcon.addEventListener('click', () => {
      hideBox()
    })
    searchListCloseIcon.addEventListener('keydown', event => {
      if (event.keyCode === 13) {
        hideBox()
      }
    })
    searchListCloseIcon.addEventListener('keydown', event => {
      if (event.keyCode === 13) {
        hideBox()
      }
    })
    searchButton.addEventListener('click', () => {
      searchForm.submit()
    })
    searchInput.addEventListener('input', function (e) {
      currentFocus = -1 // highlighted search result item
      let userData = e.target.value // user entered data
      if (userData !== '') {
        searchListCloseIcon.style.display = 'block'
        let autocompleteAPI = `${document
          .querySelector('ameriprise-menu')
          .getAttribute('data-search-api')}/${userData}`
        let request = new XMLHttpRequest()
        request.open('Get', autocompleteAPI)
        request.withCredentials = true
        request.send()
        request.onload = function () {
          if (request.status !== 200 || request.responseText === '[]') {
            return
          }
          response = JSON.parse(request.responseText)
          let searchResponseArray = []
          searchResponseArray = response.filter(data => {
            return data
              .toLocaleLowerCase()
              .startsWith(userData.toLocaleLowerCase())
          })
          searchResponseArray = searchResponseArray.map(data => {
            data = data.replace(
              userData.toLocaleLowerCase(),
              '<b>' + userData + '</b>'
            )
            // passing return data inside li tag
            return '<li>' + data.toLocaleLowerCase() + '</li>'
          })
          resultBox = document
            .querySelector('ameriprise-menu')
            .shadowRoot.querySelector(
              'nav.amp-nav-level-one .search-result-box'
            )
          resultBoxMobile = document
            .querySelector('ameriprise-menu')
            .shadowRoot.querySelector(
              'nav.amp-nav-level-two .search-result-box'
            )
          if (!IsLargeViewport()) {
            resultBox = resultBoxMobile
          }
          if (searchInput.value !== '') {
            resultBox.style.display = 'block'
            resultBox.classList.add('active')
          }
          showSuggestions(searchResponseArray)
          let searchResults = document
            .querySelector('ameriprise-menu')
            .shadowRoot.querySelector('.search-result-box')
            .querySelectorAll('li')
          let searchResultsMobile = document
            .querySelector('ameriprise-menu')
            .shadowRoot.querySelector(
              'nav.amp-nav-level-two .search-result-box'
            )
            .querySelectorAll('li')
          if (!IsLargeViewport()) {
            searchResults = searchResultsMobile
          }
          for (let i = 0; i < searchResults.length; i++) {
            searchResults[i].classList.add('search-result-item')
            searchResults[i].addEventListener('click', () => {
              searchInput.value = searchResults[i].textContent
              searchForm.submit()
            })
          }
          searchInput.onkeyup = key => {
            let i
            if (key.keyCode === 40) {
              currentFocus++
              for (i = 0; i < searchResults.length; i++) {
                searchResults[i].classList.remove('search-result-item-active')
              }
              if (currentFocus >= searchResults.length) currentFocus = 0
              if (currentFocus < 0) currentFocus = searchResults.length - 1
              searchResults[currentFocus].classList.add(
                'search-result-item-active'
              )
            } else if (key.keyCode === 38) {
              currentFocus--
              for (i = 0; i < searchResults.length; i++) {
                searchResults[i].classList.remove('search-result-item-active')
              }
              if (currentFocus >= searchResults.length) currentFocus = 0
              if (currentFocus < 0) currentFocus = searchResults.length - 1
              searchResults[currentFocus].classList.add(
                'search-result-item-active'
              )
            } else if (key.keyCode === 13) {
              if (currentFocus > -1) {
                searchInput.value = searchResults[currentFocus].textContent
              }
              searchForm.submit()
            } else if (key.keyCode === 27) {
              resultBox.style.display = 'none'
            }
          }
        }
      } else {
        hideBox()
      }
    })
  }

  showSuggestions (list) {
    let searchInput = document
      .querySelector('ameriprise-menu')
      .shadow.querySelector('nav.amp-nav-level-one .amp-search-input')
    let searchInputMobile = document
      .querySelector('ameriprise-menu')
      .shadow.querySelector('nav.amp-nav-level-two .amp-search-input')
    let resultBox = document
      .querySelector('ameriprise-menu')
      .shadow.querySelector('nav.amp-nav-level-one .search-result-box')
    let resultBoxMobile = document
      .querySelector('ameriprise-menu')
      .shadow.querySelector('nav.amp-nav-level-two .search-result-box')

    if (!IsLargeViewport()) {
      searchInput = searchInputMobile
      resultBox = resultBoxMobile
    }
    let listData
    if (!list.length) {
      var userValue = searchInput.value
      listData = '<li>' + userValue + '</li>'
    } else {
      listData = list.join('')
    }
    if (resultBox) {
      resultBox.innerHTML = listData
    }
  }

  hideBox () {
    let searchInput = document
      .querySelector('ameriprise-menu')
      .shadow.querySelector('nav.amp-nav-level-one .amp-search-input')
    let searchInputMobile = document
      .querySelector('ameriprise-menu')
      .shadow.querySelector('nav.amp-nav-level-two .amp-search-input')
    let resultBox = document
      .querySelector('ameriprise-menu')
      .shadow.querySelector('nav.amp-nav-level-one .search-result-box')
    let resultBoxMobile = document
      .querySelector('ameriprise-menu')
      .shadow.querySelector('nav.amp-nav-level-two .search-result-box')
    let searchListCloseIcon = document
      .querySelector('ameriprise-menu')
      .shadow.querySelector('nav.amp-nav-level-one .search-list-close-icon')
    let searchListCloseIconMobile = document
      .querySelector('ameriprise-menu')
      .shadow.querySelector('nav.amp-nav-level-two .search-list-close-icon')

    if (!IsLargeViewport()) {
      searchInput = searchInputMobile
      resultBox = resultBoxMobile
      searchListCloseIcon = searchListCloseIconMobile
    }

    searchInput.value = ''
    resultBox.style.display = 'none'
    searchListCloseIcon.style.display = 'none'
  }

  setupMainEventHandlers () {
    let resizeTimer
    let clickTimer

    // If window is resized - display "hamburger menu" if small/medium viewport
    window.addEventListener('resize', event => {
      clearTimeout(resizeTimer)

      resizeTimer = setTimeout(() => {
        this.resized()
      }, 20)
    })

    // When "hamburger menu" icon is clicked, display menu
    window.addEventListener('menuclick', event => {
      clearTimeout(clickTimer)
      clickTimer = setTimeout(() => {
        this.clicked(event.detail.currentTarget)
      }, 20)
    })
    this.setupScroll()

    const logoutButtons = this.shadowRoot.querySelectorAll('.amp-logout')

    Array.from(logoutButtons).forEach(logoutButton => {
      logoutButton.addEventListener('click', event => {
        this.setAuthStateCookie('expired')
      })
    })
  }

  setupScroll () {
    let scrollPosition = 0
    let lastScrollPosition = 0
    let ticking = false
    let body = document.getElementsByTagName('body')[0]

    if (!_isIE) {
      window.addEventListener('scroll', e => {
        scrollPosition = window.scrollY

        if (!ticking) {
          window.requestAnimationFrame(() => {
            if (
              IsLargeViewport() &&
              getComputedStyle(body).overflowY !== 'hidden'
            ) {
              if (scrollPosition > 72 && scrollPosition <= lastScrollPosition) {
                this.nav.classList.add('sticky')
              } else {
                this.nav.classList.remove('sticky')
              }
            }

            lastScrollPosition = scrollPosition

            ticking = false
          })

          ticking = true
        }
      })
    }
  }

  setupAnalyticsMenu () {
    /* Analytics click event - MENU */
    const headerNavContent = this.shadow.querySelector('.amp-nav')
    const navLinks =
      headerNavContent && headerNavContent.getElementsByTagName('a')

    navLinks &&
      navLinks.length > 0 &&
      navLinks.forEach(link => {
        link &&
          link.addEventListener('click', event => {
            const message =
              event.currentTarget &&
              event.currentTarget.getAttribute('data-analytics')
            const navClass = event.currentTarget.classList
            const navItem = event.currentTarget.offsetParent

            if (
              navClass.contains('amp-search') ||
              (!IsLargeViewport() &&
                (navItem.classList.contains('amp-mainNav-backButton') ||
                  (navItem.classList.contains(
                    'amp-mainNav-item--hasDropdown'
                  ) &&
                    navItem.offsetParent.classList.contains(
                      'amp-nav-level-one'
                    )) ||
                  navItem.classList.contains('amp-childNav-item--hasDropdown')))
            ) {
              return
            }

            let type = navItem.classList.contains('amp-utilityNav-item')
              ? 'header'
              : navClass.contains('amp-mainNav-link')
                ? 'nav'
                : 'navtray'

            try {
              const analytics = new Analytics()
              analytics.ping(message, type)
            } catch (e) {}
          })
      })
  }

  setupAnalyticsStickyNav () {
    /* Analytics click event - STICKYNAV */
    const stickyNav = this.shadow.querySelector('.amp-utilityNav')
    const findAnAdvisorStikcy =
      stickyNav && stickyNav.querySelector('.analytics-FindAnAdvisor')
    if (findAnAdvisorStikcy) {
      findAnAdvisorStikcy.addEventListener('click', () => {
        try {
          const customAnalytics = new Analytics()
          customAnalytics.setSatelliteTrack('initiativeFindAnAdvisor')
        } catch (e) {}
      })
    }

    /* Analytics click event - BREADCRUMB */
    const headerBreadcrumbContent =
      this.shadow.querySelector('.amp-breadcrumb')
    const breadcrumbLinks =
      headerBreadcrumbContent &&
      headerBreadcrumbContent.getElementsByTagName('a')

    breadcrumbLinks &&
      breadcrumbLinks.length > 0 &&
      breadcrumbLinks.forEach(link => {
        link &&
          link.addEventListener('click', event => {
            const navMessage =
              event.currentTarget &&
              event.currentTarget.getAttribute('data-analytics')

            let type = 'breadcrumb'

            try {
              const footerAnalytics = new Analytics()
              footerAnalytics.ping(navMessage, type)
            } catch (e) {}
          })
      })
  }

  setAuthStateCookie (status) {
    jsCookie.set('lastAmpAuthState', status, {
      domain: 'ameriprise.com',
      path: '/',
      expires: 365
    })
  }

  hideWelcome () {
    const clientWelcome = this.shadow.querySelector('.amp-clientWelcome')

    clientWelcome.classList.add('u-hidden')

    const clientAccount = this.shadow.querySelector('.amp-clientAccount')

    clientAccount.classList.add('u-hidden')
    clientAccount.classList.remove('u-flex')
  }

  async awaitInitClient () {
    await this.initClient()
  }

  async initClient () {
    const host = window.location.hostname

    // ignore ipp, advisors, newsroom
    if (
      /localhost/.test(host) ||
      /ipp\.ameriprise\.com/.test(host) ||
      /ipp\.qa\.ameriprise\.com/.test(host) ||
      /ameripriseadvisors\.com/.test(host) ||
      /newsroom\.ameriprise\.com/.test(host)
    ) {
      this.hideWelcome()

      return
    }

    const { data, errors } = await getUser()

    if (errors && errors.length > 0) {
      this.hideWelcome()
    } else if (data && data.client && data.client.user) {
      const clientUser = data.client.user

      if (clientUser && clientUser.clFirstNm) {
        const clientActiveUserFields = this.shadow.querySelectorAll(
          '.amp-clientActiveProfile'
        )
        Array.from(clientActiveUserFields).forEach(clientActiveUserField => {
          clientActiveUserField.innerHTML = clientUser.clFirstNm
        })
      }
    } else {
      this.hideWelcome()
    }

    return true
  }

  async awaitInitPersonalization () {
    let data = {}
    try {
      const response = await fetch('/relevanceapi/visitorinfo', {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(response => response.json())
        .then(data => data)
      // console.log(response)
      data =
        response.visitor.targetingData.advisorcollector?.extraData?.attributes
    } catch (error) {
      // console.log(error)
    }

    // Mock data for local host
    if (window.location.hostname === 'localhost') {
      data = {
        image:
          'https://cdn.ameriprisecontent.com/cds/alwp/echelonwealthpartners/echelon-wealth-partners_84741_355x266.jpg',
        name: 'Echelon Wealth Partners',
        tagline:
          'A private wealth advisory practice of Ameriprise Financial Services, LLC',
        url: 'https://www.qa.ameripriseadvisors.com/team/echelon-wealth-partners',
        phonenumber: '123-456-7890',
        email: 'echelonwealthpartners@ampf.com'
      }
    }

    if (data !== undefined && Object.keys(data).length > 0) {
      const personalizedMobileButton = PersonalizedMobileButton(data)
      let mobileFindAdvisorButton = this.shadow.querySelector(
        '.amp-utilityNav .analytics-FindAnAdvisor'
      )
      if (mobileFindAdvisorButton) {
        mobileFindAdvisorButton.replaceWith(personalizedMobileButton)
      }
      let stickyFAB = this.shadow.querySelector(
        '.amp-utilityNav .amp-mainNav-item-button .analytics-FindAnAdvisor'
      )
      if (stickyFAB) {
        const ulElt = document.createElement('ul')
        ulElt.id = 'personalized-sticky-advisor'
        stickyFAB.replaceWith(ulElt)
        const personalizedButtonJson = PersonalizedButtonJson(data)
        addMenuItem(ulElt, personalizedButtonJson.menuItems[0], 0, true, '')
      }
    }
    return true
  }
}

window.customElements.define('ameriprise-menu', AmeripriseMenu)
