/*
 *    This software or document includes material copied from or derived from W3 Navigation Menubar Example
 *    https://www.w3.org/TR/wai-aria-practices-1.1/examples/menubar/menubar-1/menubar-1.html.
 *    Copyright © 2021 W3C® (MIT, ERCIM, Keio, Beihang).
 */

import { PopupMenu } from './popupmenu.js'

export default class MenubarItem {
  constructor (domNode, menuObj, index, mouseEnterCallback) {
    this.menu = menuObj
    this.domNode = domNode
    this.desktopPopupMenu = false
    this.mobilePopupMenu = false
    this.index = index
    this.mouseEnterCallback = mouseEnterCallback

    this.hasHover = false

    this.isMenubarItem = true
    this.timeout = null
    this.handleKeydown = this.handleKeydown.bind(this)
    this.handleMouseEnter = this.handleMouseEnter.bind(this)
    this.handleMouseLeave = this.handleMouseLeave.bind(this)

    this.keyCode = Object.freeze({
      TAB: 9,
      RETURN: 13,
      ESC: 27,
      SPACE: 32,
      PAGEUP: 33,
      PAGEDOWN: 34,
      END: 35,
      HOME: 36,
      LEFT: 37,
      UP: 38,
      RIGHT: 39,
      DOWN: 40
    })
  }

  init () {
    this.domNode.addEventListener('keydown', this.handleKeydown)
    this.domNode.addEventListener('mouseenter', this.handleMouseEnter)
    this.domNode.addEventListener('mouseleave', this.handleMouseLeave)
  }

  initPopupMenu (desktopMode) {
    // if we already have the menu for current mode do nothing
    if (
      (this.desktopPopupMenu && desktopMode) ||
      (this.mobilePopupMenu && !desktopMode)
    ) {
      return
    }
    // if we have a menu it is for the wrong mode - deInit it
    if (this.desktopPopupMenu) this.desktopPopupMenu.deInit()
    if (this.mobilePopupMenu) this.mobilePopupMenu.deInit()

    const menuNode = desktopMode
      ? this.domNode.querySelector('ul')
      : this.domNode.closest('nav')
    if (menuNode) {
      this.popupMenu = new PopupMenu(menuNode, this)
      this.popupMenu.init()
    }
    this.desktopMode = desktopMode
    if (desktopMode) {
      this.desktopPopupMenu = this.popupMenu
      this.mobilePopupMenu = null
    } else {
      this.mobilePopupMenu = this.popupMenu
      this.desktopPopupMenu = null
    }
  }
  handleKeydown (event) {
    if (this.desktopMode) {
      this.handleKeydownDesktop(event)
    } else {
      this.handleKeydownMobile(event)
    }
  }

  handleKeydownDesktop (event) {
    var char = event.key
    var flag = false

    function isPrintableCharacter (str) {
      return str.length === 1 && str.match(/\S/)
    }

    switch (event.keyCode) {
    case this.keyCode.SPACE:
    case this.keyCode.RETURN:
      if (this.popupMenu) {
        if (!this.isExpanded()) {
          this.popupMenu.open()
          flag = true
        } // otherwise (expanded or no menu items)  fall through to default action (click)
        break
      }

      var clickEvent
      var target = event.currentTarget
      // ensure we send click to 'a' node, not any node surrounding so that these trigger redirects
      if (target.nodeName !== 'A') {
        target = target.querySelector('a')
      }
      // Create simulated mouse event to mimic the behavior of ATs
      // and let the event handler handleClick do the housekeeping.
      try {
        clickEvent = new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: true
        })
      } catch (err) {
        if (document.createEvent) {
          // DOM Level 3 for IE 9+
          clickEvent = document.createEvent('MouseEvents')
          clickEvent.initEvent('click', true, true)
        }
      }
      target.dispatchEvent(clickEvent)
      flag = true
      break

    case this.keyCode.TAB:
      if (this.popupMenu && this.isExpanded()) {
        if (event.shiftKey) {
          this.popupMenu.setFocusToLastItem()
        } else {
          this.popupMenu.setFocusToFirstItem()
        }
        flag = true
      }
      break

    case this.keyCode.ESC:
      if (this.popupMenu) {
        this.popupMenu.close(true)
      }
      break

    default:
      if (isPrintableCharacter(char)) {
        this.menu.setFocusByFirstCharacter(this, char)
        flag = true
      }
      break
    }

    if (flag) {
      event.stopPropagation()
      event.preventDefault()
    }
  }

  handleKeydownMobile (event) {
    var char = event.key
    var flag = false

    function isPrintableCharacter (str) {
      return str.length === 1 && str.match(/\S/)
    }

    switch (event.keyCode) {
    case this.keyCode.SPACE:
    case this.keyCode.RETURN:
      if (this.popupMenu) {
        flag = true
        break
      }
      break

    case this.keyCode.TAB:
      if (this.popupMenu) {
        this.popupMenu.close(true)
      }
      break

    case this.keyCode.ESC:
      this.domNode.closest('.amp-nav-inner').classList.remove('is-visible')
      this.menu.iconChange('close')
      break

    default:
      if (isPrintableCharacter(char)) {
        this.menu.setFocusByFirstCharacter(this, char)
        flag = true
      }
      break
    }

    if (flag) {
      event.stopPropagation()
      event.preventDefault()
    }
  }

  setExpanded (value) {
    if (value) {
      this.domNode.setAttribute('aria-expanded', 'true')
    } else {
      this.domNode.setAttribute('aria-expanded', 'false')
    }
  }
  isExpanded () {
    return this.domNode.getAttribute('aria-expanded') === 'true'
  }

  handleMouseEnter (event) {
    this.hasHover = true
    clearTimeout(this.timeout)
    if (this.popupMenu) {
      this.mouseEnterCallback(this.index)
      this.popupMenu.open()
    }
  }

  handleMouseLeave (event) {
    this.hasHover = false
    if (!this.popupMenu) {
      return
    }
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      if (!this.hasHover) {
        this.popupMenu.close()
      }
    }, 500)
  }
}
