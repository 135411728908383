import Analytics from '../../SiteNav/analytics.js'
import xss from 'xss'

export const PersonalizedButton = data => {
  const safeUrl = xss(data.url)
  const safeEmail = xss(data.email)
  const safeName = xss(data.name)
  const safePhone = xss(data.phonenumber)

  const div = document.createElement('div')
  div.setAttribute('id', 'personalized-nav')

  const personalizedButton = document.createElement('button')
  personalizedButton.setAttribute('id', 'personalized-button')
  personalizedButton.innerText = `Contact ${safeName}`
  // personalizedButton.classList.add('u-textBold', 'u-textColorWhite')
  personalizedButton.classList.add(
    'Button',
    'Button--primary',
    'Button--small'
  )
  personalizedButton.setAttribute('tabindex', '-1')

  const list = document.createElement('ul')
  list.classList.add(
    'List',
    'List--selectable',
    'u-paddingLeft0',
    'u-posAbsolute',
    'u-bgColorWhite',
    'personalized-contact-list',
    'amp-childNav'
  )
  const request = document.createElement('li')
  request.classList.add(
    'List-item',
    'amp-childNav-item',
    'u-padding0',
    'u-border0'
  )
  request.innerHTML = `<div class="amp-childNav-link-wrapper"><a class="Link amp-childNav-link" href="${
    safeUrl + '?openFormModal=request-contact'
  }">Request consultation</a></div>`
  request.addEventListener(
    'click',
    notifyAnalytics.bind(undefined, 'personalized nav', 'Request consultation')
  )

  const email = document.createElement('li')
  email.classList.add(
    'List-item',
    'amp-childNav-item',
    'u-padding0',
    'u-border0'
  )
  email.innerHTML = `<div class="amp-childNav-link-wrapper"><a class="Link amp-childNav-link" href="mailto:${safeEmail}">Email ${safeName}</a></div>`
  email.addEventListener(
    'click',
    notifyAnalytics.bind(undefined, 'personalized nav', 'Email')
  )

  const call = document.createElement('li')
  call.classList.add(
    'List-item',
    'amp-childNav-item',
    'u-padding0',
    'u-border0'
  )
  call.innerHTML = `<div class="amp-childNav-link-wrapper"><a class="Link amp-childNav-link" href="tel:${safePhone}">Call ${safeName}</a></div>`
  call.addEventListener(
    'click',
    notifyAnalytics.bind(undefined, 'personalized nav', 'Call')
  )

  if (data.email) list.appendChild(request) // request form needs email so no email -> no form
  if (data.email) list.appendChild(email)
  if (data.phonenumber) list.appendChild(call)
  div.appendChild(personalizedButton)
  div.appendChild(list)

  return div
}

export const PersonalizedButtonJson = data => {
  let personalizedButtonJson = {}
  personalizedButtonJson.name = 'personalized-nav'
  personalizedButtonJson.menuItems = []

  let baseItem = {}
  baseItem.name = `Contact ${data.name}`
  baseItem.menuItems = []
  personalizedButtonJson.menuItems[0] = baseItem

  let consultItem = {}
  let consultItemElt = {}
  consultItem.HstMenuItemRepresentation = consultItemElt

  let curElt = 0

  if (data.email) {
    consultItemElt.name = 'Request consultation'
    consultItemElt.url = data.url + '?openFormModal=request-contact'
    consultItemElt.menuItems = []
    consultItem.analyticsType = 'personalized nav'
    consultItem.analyticsName = 'Request consultation'
    baseItem.menuItems[curElt++] = consultItem

    let emailItem = {}
    let emailItemElt = {}
    emailItem.HstMenuItemRepresentation = emailItemElt

    emailItemElt.name = 'Email ' + data.name
    emailItemElt.url = 'mailto' + data.email
    emailItemElt.analyticsType = 'personalized nav'
    emailItemElt.analyticsName = 'Email'
    emailItemElt.menuItems = []
    baseItem.menuItems[curElt++] = emailItem
  }

  if (data.phonenumber) {
    let callItem = {}
    let callItemElt = {}
    callItem.HstMenuItemRepresentation = callItemElt

    callItemElt.name = 'Call ' + data.name
    callItemElt.url = 'tel:' + data.phonenumber
    callItemElt.analyticsType = 'personalized nav'
    callItemElt.analyticsName = 'Call'
    callItemElt.menuItems = []
    baseItem.menuItems[curElt++] = callItem
  }

  return personalizedButtonJson
}

export function notifyAnalytics (type, message) {
  // Analytics Tagging
  try {
    const buttonAnalytics = new Analytics()
    buttonAnalytics.ping(message, type)
  } catch (e) {}
}

export default PersonalizedButton
