import { createElement } from '@cwp/utils'

const API_KEY = 'ZBlbEg5x3sNl'
const FILENAME = `IRA-ROLLOVER-EVALUATOR-${new Date().getTime()}.pdf`
const UNIT = 'in'
const UNITINNUMBERS = 0.25
const PAGESIZE = 'Letter'
const ORIENTATION = 'portrait'
const PRINTCMT = 'print'

let PDFSETTINGS = {
  apikey: API_KEY,
  filename: FILENAME,
  unit: UNIT,
  left: UNITINNUMBERS,
  right: UNITINNUMBERS,
  top: UNITINNUMBERS,
  bottom: UNITINNUMBERS,
  pagesize: PAGESIZE,
  orientation: ORIENTATION,
  cmt: PRINTCMT,
  html: ''
}

function buildForm (data) {
  const result = createElement('form', {
    action: 'https://ampf.htm2pdf.co.uk/urltopdf',
    method: 'POST',
    style: 'display: none',
    target: '_self'
  })

  Object.keys(data).forEach(key => {
    const input = createElement('input', {
      name: key,
      type: 'hidden',
      value: data[key]
    })

    result.appendChild(input)
  })

  return result
}

export default function convert (html = '') {
  PDFSETTINGS = { ...PDFSETTINGS, html: html }
  const form = buildForm(PDFSETTINGS)

  document.body.appendChild(form)
  form.submit()
  form.remove()
}
