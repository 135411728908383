import { fetchJSON } from '../common/fetch'
import objPath from 'object-path'
import { buildQuery } from '@cwp/utils'
import { push } from './_utils'

let cache

export default async function get () {
  const result = { data: cache, errors: [] }

  /* Test the user service in ws1-QA, we will remove after the testing is done */
  const currentHostName = window.location.hostname

  const isLocal = /localhost|127\.0\.0\.1/i.test(currentHostName)
  const isDev = /dev|ws1.dev\./i.test(currentHostName)
  const isQa = /qa|ws1.qa\./i.test(currentHostName)

  const secureQA = 'https://secure-01.qa.ameriprise.com'
  const securePROD = 'https://secure.ameriprise.com'

  let userService = '/scs.svc/User(usrCtx=\'SSO.GUID\',usrId=\'0\''

  if (isLocal || isDev || isQa) {
    userService = secureQA + userService
  } else {
    userService = securePROD + userService
  }

  if (!cache && cache !== false) {
    try {
      const query = buildQuery({ $expand: 'client', $format: 'json' })
      const data = await fetchJSON(`${userService})?${query}`)
      const { clCtx, clId, person } = objPath.get(data, 'd.client', {})
      const { usrCtx, usrId } = objPath.get(data, 'd', {})

      cache = {
        client: {
          ctx: clCtx,
          id: clId,
          user: person
        },
        usrCtx,
        usrId
      }

      result.data = cache
    } catch (error) {
      result.errors = push(result.errors, error)
      cache = false
    }
  }

  return result
}
