import React, { useEffect, useState } from 'react'
import xss from 'xss'
import PropTypes from 'prop-types'
import './navigation.less'
import jsCookie from 'js-cookie'
import Analytics from '../../SiteNav/analytics.js'
import { useFooterPrivacyLink } from './useFooterPrivacyLink'

export const Navigation = props => {
  const { menuName, menuApiPath, children, version } = props
  const [menuItems, setMenuItems] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const showPrivacyLink = useFooterPrivacyLink()

  useEffect(() => {
    function getMenuType () {
      if (menuName) {
        return menuName
      }

      const userStatus = jsCookie.get('lastAmpAuthState') ?? 'unrecognized'

      switch (userStatus) {
      case 'active':
        return 'footer-menu-client-active'
      case 'expired':
        // footer-menu-expired is not in use
        return 'footer-menu-prospect'
      default:
        return 'footer-menu-prospect'
      }
    }

    const fetchData = async () => {
      setIsLoading(true)

      const menuType = getMenuType()
      const cacheId = `${menuType}-cache-version-${version ?? 0}`

      let menuItemsCache
      if (sessionStorage.getItem(cacheId) === null) {
        menuItemsCache = []
        try {
          const body = await fetch(`${menuApiPath}/${menuType}`, {
            credentials: 'include'
          })
            .then(response => {
              return response.json()
            })
            .catch(err => {
              console.log('failed to fetch ' + err.message)
            })

          body.menuItems.map(hstMenuItem => {
            menuItemsCache.push({
              name: hstMenuItem.HstMenuItemRepresentation.name,
              url: hstMenuItem.HstMenuItemRepresentation.url
            })
          })

          sessionStorage.setItem(cacheId, JSON.stringify(menuItemsCache))
          setMenuItems(menuItemsCache)
        } catch (err) {
          console.log('failed to fetch menu data', err.message)
        }
      } else {
        menuItemsCache = JSON.parse(sessionStorage.getItem(cacheId))
        setMenuItems(menuItemsCache)
      }
      setIsLoading(false)
    }

    fetchData()
  }, [menuApiPath, menuName, version])

  function handleClick (event) {
    try {
      const footerAnalytics = new Analytics()
      footerAnalytics.ping(event.target.text, 'footer')
    } catch (e) {
      console.log('Analytics ping failed')
    }
  }

  return (
    <div className="u-adel-reset amp-Footer u-pt-hidden u-block">
      <div className="u-sizeConstrained u-paddingVert6gu u-bgColorWhite">
        <div className="Grid Grid--fill Grid--withGutter">
          <div className="u-sizeFill u-lg-size2of3">
            <nav role="navigation">
              {isLoading && (
                <div className="Loading u-textColorBrandLight">
                  <div className="Loading-indicator"></div>
                  <div className="Loading-indicator"></div>
                  <div className="Loading-indicator"></div>
                </div>
              )}
              {!isLoading && (
                <ul
                  className="u-decoUnbulleted"
                  data-qa="FooterNavLevelOne"
                  role="menu"
                >
                  {menuItems?.map((menuItem, index) =>
                    menuItem.name !== 'Do Not Sell or Share My Personal Data' ||
                    showPrivacyLink ? (
                        <li
                          key={index}
                          className={
                            'u-marginBottom6gu' +
                          (menuItem.name === 'Customer Service'
                            ? ' u-lg-hidden'
                            : '')
                          }
                          role="menuitem"
                        >
                          <a
                            onClick={handleClick}
                            className="Link u-textBold u-textColorDefault"
                            href={xss(menuItem.url)}
                          >
                            {xss(menuItem.name)}
                          </a>
                        </li>
                      ) : (
                        ''
                      )
                  )}
                </ul>
              )}
            </nav>
          </div>
          <div className="u-sm-hidden u-md-hidden u-lg-size1of3">
            <span>{children}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

Navigation.propTypes = {
  menuApiPath: PropTypes.string.isRequired,
  menuName: PropTypes.string,
  version: PropTypes.string,
  children: PropTypes.node
}

// TODO href should be passed as prop set by SSR as to make relative to environment
Navigation.defaultProps = {
  menuApiPath: 'https://www.qa.ameriprise.com/cfgapi/hst/menus/ampcom',
  version: 0,
  children: (
    <>
      <span className="u-textPrimary u-textBold u-borderBottom u-borderColorNeutralLight u-paddingBottom2gu u-marginBottom2gu u-block">
        Customer Service
      </span>
      <p className="u-marginBottom6gu u-textColorLight">
        Visit our Customer Service section for contact information and answers
        to commonly asked questions.
      </p>
      <a className="Link" href="https://www.ameriprise.com/customer-service">
        Learn more
      </a>
    </>
  )
}

export default Navigation
