// eslint-disable max-classes-per-file

/*
 *    This software or document includes material copied from or derived from W3 Navigation Menubar Example
 *    https://www.w3.org/TR/wai-aria-practices-1.1/examples/menubar/menubar-1/menubar-1.html.
 *    Copyright © 2021 W3C® (MIT, ERCIM, Keio, Beihang).
 */
export class PopupMenu {
  constructor (domNode, controllerObj) {
    var msgPrefix = 'PopupMenu constructor argument domNode '

    // Check whether domNode is a DOM element
    if (!(domNode instanceof Element)) {
      throw new TypeError(msgPrefix + 'is not a DOM Element.')
    }

    // Check whether domNode descendant elements have A elements
    var childElement = domNode.firstElementChild
    while (childElement) {
      var menuitem = childElement.firstElementChild
      if (menuitem && menuitem === 'A') {
        throw new Error(
          msgPrefix + 'has descendant elements that are not A elements.'
        )
      }
      childElement = childElement.nextElementSibling
    }

    this.isMenubar = false

    this.domNode = domNode
    this.controller = controllerObj

    this.menuItems = [] // See PopupMenu init method
    this.firstChars = [] // See PopupMenu init method

    this.firstItem = null // See PopupMenu init method
    this.lastItem = null // See PopupMenu init method

    this.hasFocus = false // See MenuItem handleFocus, handleBlur
    this.hasHover = false
  }

  /*
   *   @method PopupMenu.prototype.init
   *
   *   @desc
   *       Traverse domNode children to configure each menuitem and populate
   *       menuItems array. Initialize firstItem and lastItem properties.
   */
  init () {
    var menuElement, menuItem, textContent, numItems

    // selecting based on amp-childNav-link or amp-granchildNav-item as the most resilient options to change
    var selector = this.domNode.classList.contains('amp-childNav')
      ? '.amp-childNav-link'
      : '.amp-grandchildNav-item a'
    var elements = this.domNode.querySelectorAll(selector)

    for (var i = 0; i < elements.length; i++) {
      menuElement = elements[i]
      menuItem = new MenuItem(menuElement, this)
      menuItem.init()
      this.menuItems.push(menuItem)
      textContent = menuElement.textContent.trim()
      this.firstChars.push(textContent.substring(0, 1).toLowerCase())
    }

    // Use populated menuItems array to initialize firstItem and lastItem.
    numItems = this.menuItems.length
    if (numItems > 0) {
      this.firstItem = this.menuItems[0]
      this.lastItem = this.menuItems[numItems - 1]
    }
  }

  deInit () {
    for (var i = 0; i < this.menuItems.length; i++) {
      this.menuItems[i].deInit()
    }
  }

  /* FOCUS MANAGEMENT METHODS */

  setFocusToController (command, flag) {
    if (typeof command !== 'string') {
      command = ''
    }

    function setFocusToMenubarItem (controller, close) {
      while (controller) {
        if (controller.isMenubarItem) {
          controller.domNode.focus()
          return controller
        } else {
          if (close) {
            controller.menu.close(true)
          }
          controller.hasFocus = false
        }
        controller = controller.menu.controller
      }
      return false
    }

    if (command === '') {
      if (this.controller && this.controller.domNode) {
        const aElt = this.controller.domNode.querySelector(
          '.amp-mainNav-link-wrapper a'
        ) // the 'a' element has tab-stop
        if (aElt) {
          aElt.focus()
        } else {
          this.controller.domNode.focus()
        }
      }
      return
    }

    if (!this.controller.isMenubarItem) {
      this.controller.domNode.focus()
      this.close()

      if (command === 'next') {
        var menubarItem = setFocusToMenubarItem(this.controller, false)
        if (menubarItem) {
          menubarItem.menu.setFocusToNextItem(menubarItem, flag)
        }
      }
    } else {
      if (command === 'previous') {
        this.controller.menu.setFocusToPreviousItem(this.controller, flag)
      } else if (command === 'next') {
        this.controller.menu.setFocusToNextItem(this.controller, flag)
      }
    }
  }

  setFocusToFirstItem () {
    for (var i = 0; i < this.menuItems.length; i++) {
      if (this.menuItems[i].domNode.offsetParent != null) {
        this.setFocusToItem(this.menuItems[i])
        break
      }
    }
  }

  setFocusToLastItem () {
    this.setFocusToItem(this.lastItem)
  }

  setFocusToPreviousItem (currentItem) {
    if (currentItem === this.firstItem) {
      this.setFocusToController()
      this.close()
    } else {
      const index = this.menuItems.indexOf(currentItem)
      if (this.menuItems[index - 1].domNode.offsetParent === null) {
        // trying to set it to invisible elt
        this.setFocusToPreviousItem(this.menuItems[index - 1]) // skip the invisible elt
      } else {
        this.setFocusToItem(this.menuItems[index - 1])
      }
    }
  }

  setFocusToNextItem = function (currentItem) {
    var index

    if (currentItem === this.lastItem) {
      this.setFocusToController()
      this.close()
      this.controller.setFocusToNextItem()
    } else {
      index = this.menuItems.indexOf(currentItem)
      this.setFocusToItem(this.menuItems[index + 1])
    }
  }

  setFocusByFirstCharacter (currentItem, char) {
    var start
    var index
    char = char.toLowerCase()

    // Get start index for search based on position of currentItem
    start = this.menuItems.indexOf(currentItem) + 1
    if (start === this.menuItems.length) {
      start = 0
    }

    // Check remaining slots in the menu
    index = this.getIndexFirstChars(start, char)

    // If not found in remaining slots, check from beginning
    if (index === -1) {
      index = this.getIndexFirstChars(0, char)
    }

    // If match was found...
    if (index > -1) {
      this.setFocusToItem(this.menuItems[index])
    }
  }

  getIndexFirstChars (startIndex, char) {
    for (var i = startIndex; i < this.firstChars.length; i++) {
      if (char === this.firstChars[i]) {
        return i
      }
    }
    return -1
  }

  // set focus to item after setting any popup menu hidden - avoid having submenus pop open without right key press
  setFocusToItem (item) {
    item.domNode.focus()
  }

  /* MENU DISPLAY METHODS */

  open () {
    if (this.isOpen()) {
      return
    }
    this.domNode.classList.add('active')
    this.controller.setExpanded(true)
  }
  isOpen () {
    return this.domNode.classList.contains('active')
  }

  // when this item closes clear all 'u-hidden' from submenus to enable normal mouse navigation
  close (force) {
    if (!this.isOpen()) {
      return
    }
    this.domNode.classList.remove('active')
    this.controller.setExpanded(false)
  }
}

export class MenuItem {
  constructor (domNode, menuObj) {
    this.domNode = domNode
    this.menu = menuObj
    this.popupMenu = false
    this.isMenubarItem = false

    this.keyCode = Object.freeze({
      TAB: 9,
      RETURN: 13,
      ESC: 27,
      SPACE: 32,
      PAGEUP: 33,
      PAGEDOWN: 34,
      END: 35,
      HOME: 36,
      LEFT: 37,
      UP: 38,
      RIGHT: 39,
      DOWN: 40
    })
  }

  init () {
    this.domNode.addEventListener('keydown', this.handleKeydown.bind(this))
    this.domNode.addEventListener('focus', this.handleFocus.bind(this))
    this.domNode.addEventListener('blur', this.handleBlur.bind(this))

    // Initialize flyout menu

    var nextElement = this.domNode.classList.contains('amp-childNav-link')
      ? this.domNode.parentElement.parentElement.querySelector(
        '.amp-grandchildNav'
      )
      : null

    if (nextElement && nextElement.tagName === 'UL') {
      this.popupMenu = new PopupMenu(nextElement, this)
      this.popupMenu.init()
    }
  }

  deInit () {
    this.domNode.removeEventListener('keydown', this.handleKeydown.bind(this))
    this.domNode.removeEventListener('focus', this.handleFocus.bind(this))
    this.domNode.removeEventListener('blur', this.handleBlur.bind(this))
    if (this.popupMenu) this.popupMenu.deInit()
  }

  isExpanded () {
    return this.domNode.getAttribute('aria-expanded') === 'true'
  }

  /* EVENT HANDLERS */

  handleKeydown (event) {
    var tgt = event.currentTarget
    var char = event.key
    var flag = false
    var clickEvent

    function isPrintableCharacter (str) {
      return str.length === 1 && str.match(/\S/)
    }

    switch (event.keyCode) {
    case this.keyCode.SPACE:
    case this.keyCode.RETURN:
      if (this.popupMenu && !this.popupMenu.isOpen()) {
        this.popupMenu.open()
      } else {
        // Create simulated mouse event to mimic the behavior of ATs
        // and let the event handler handleClick do the housekeeping.
        try {
          clickEvent = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true
          })
        } catch (err) {
          if (document.createEvent) {
            // DOM Level 3 for IE 9+
            clickEvent = document.createEvent('MouseEvents')
            clickEvent.initEvent('click', true, true)
          }
        }
        tgt.dispatchEvent(clickEvent)
      }
      flag = true
      break

    case this.keyCode.ESC:
      this.menu.setFocusToController()
      this.menu.close(true)
      flag = true
      break

    case this.keyCode.TAB:
      if (this.popupMenu && this.popupMenu.isOpen()) {
        if (event.shiftKey) {
          this.popupMenu.setFocusToLastItem()
        } else {
          this.popupMenu.setFocusToFirstItem()
        }
      } else if (event.shiftKey) {
        this.menu.setFocusToPreviousItem(this)
      } else {
        this.menu.setFocusToNextItem(this)
      }
      flag = true
      break

    default:
      if (isPrintableCharacter(char)) {
        this.menu.setFocusByFirstCharacter(this, char)
        flag = true
      }
      break
    }

    if (flag) {
      event.stopPropagation()
      event.preventDefault()
    }
  }

  setExpanded (value) {
    if (value) {
      this.domNode.setAttribute('aria-expanded', 'true')
    } else {
      this.domNode.setAttribute('aria-expanded', 'false')
    }
  }
  setFocusToNextItem () {
    this.menu.setFocusToNextItem(this)
  }

  setFocusToPreviousItem () {
    this.menu.setFocusToPreviousItem(this)
  }

  handleClick = function (event) {
    this.menu.setFocusToController()
    this.menu.close(true)
  }

  handleFocus = function (event) {
    this.menu.hasFocus = true
  }

  handleBlur = function (event) {
    this.menu.hasFocus = false
    if (
      !this.menu.domNode.contains(event.relatedTarget) &&
      !this.menu.controller.domNode.contains(event.relatedTarget)
    ) {
      this.menu.close()
    }
  }
}
