/*
 *    This software or document includes material copied from or derived from W3 Navigation Menubar Example
 *    https://www.w3.org/TR/wai-aria-practices-1.1/examples/menubar/menubar-1/menubar-1.html.
 *    Copyright © 2021 W3C® (MIT, ERCIM, Keio, Beihang).
 */

import MenubarItem from './menubaritem.js'

export default class Menubar {
  constructor (domNode, iconChange) {
    var msgPrefix = 'Menubar constructor argument menubarNode '

    // Check whether menubarNode is a DOM element
    if (!(domNode instanceof Element)) {
      throw new TypeError(msgPrefix + 'is not a DOM Element.')
    }

    // Check whether menubarNode has descendant elements
    if (domNode.childElementCount === 0) {
      throw new Error(msgPrefix + 'has no element children.')
    }

    this.isMenubar = true

    this.domNode = domNode

    this.menubarItems = [] // See Menubar init method
    this.firstChars = [] // See Menubar init method

    this.firstItem = null // See Menubar init method
    this.lastItem = null // See Menubar init method

    this.hasFocus = false // See MenubarItem handleFocus, handleBlur
    this.hasHover = false // See Menubar handleMouseover, handleMouseout
    this.focusableItems = []
    this.backClick = null
    this.parentNode = null
    this.iconChange = iconChange

    this.keyCode = Object.freeze({
      TAB: 9,
      RETURN: 13,
      ESC: 27,
      SPACE: 32,
      PAGEUP: 33,
      PAGEDOWN: 34,
      END: 35,
      HOME: 36,
      LEFT: 37,
      UP: 38,
      RIGHT: 39,
      DOWN: 40
    })

    this.handleMouseEnterChange = this.handleMouseEnterChange.bind(this)
  }

  /*
   *   @method Menubar.prototype.init
   *
   *   @desc
   *       Adds ARIA role to the menubar node
   *       Traverse menubar children for A elements to configure each A element as a ARIA menuitem
   *       and populate menuitems array. Initialize firstItem and lastItem properties.
   */
  init (parentNode, elementSelector, focusableSelector) {
    this.parentNode = parentNode
    var menubarItem, menuElement, textContent, numItems

    elementSelector =
      elementSelector || "nav.amp-nav-level-one ul[role='menubar'] > li"
    focusableSelector =
      focusableSelector || '.amp-nav-level-one .amp-utilityNav ul li a'

    // Traverse the element children of menubarNode: configure each with
    // menuitem role behavior and store reference in menuitems array.
    var elements = this.domNode.querySelectorAll(elementSelector)
    for (var i = 0; i < elements.length; i++) {
      menuElement = elements[i]

      menubarItem = new MenubarItem(
        menuElement,
        this,
        i,
        this.handleMouseEnterChange
      )
      menubarItem.init()
      this.menubarItems.push(menubarItem)
      textContent = menuElement.textContent.trim()
      this.firstChars.push(textContent.substring(0, 1).toLowerCase())
    }
    this.focusableItems = this.domNode.querySelectorAll(focusableSelector)

    // Use populated menuitems array to initialize firstItem and lastItem.
    numItems = this.menubarItems.length
    if (numItems > 0) {
      this.firstItem = this.menubarItems[0]
      this.lastItem = this.menubarItems[numItems - 1]
      this.firstItem.domNode.tabIndex = 0
    }
    this.domNode.addEventListener('keydown', this.handleKeydown.bind(this))
    this.domNode.parentElement.addEventListener(
      'focusout',
      this.focusOut.bind(this)
    )
  }

  setFocus () {
    const popoverElt = this.domNode.querySelector('.Popover')
    var firstA
    if (!popoverElt || popoverElt.classList.contains('amp-nav-level-one')) {
      firstA = this.domNode.querySelector(
        'li:not(.amp-mainNav-backButton,.amp-home) a'
      )
    } else {
      firstA = popoverElt.querySelector(
        '.amp-nav-level-two > ul.amp-mainNav li.amp-mainNav-backButton a'
      )
    }
    firstA.focus()
  }

  setMode (desktopMode) {
    for (var i = 0; i < this.menubarItems.length; i++) {
      this.menubarItems[i].initPopupMenu(desktopMode)
    }
    this.desktopMode = desktopMode
  }

  handleKeydown (event) {
    if (!this.desktopMode) this.handleKeydownMobile(event)
  }

  handleKeydownMobile (event) {
    var flag = false

    switch (event.keyCode) {
    case this.keyCode.ESC:
      if (this.backClick) {
        this.backClick(event)
      }
      break

    default:
      break
    }

    if (flag) {
      event.stopPropagation()
      event.preventDefault()
    }
  }

  handleMouseEnterChange (currIndex) {
    this.menubarItems.forEach((menuBarItem, index) => {
      if (index === currIndex || !menuBarItem.popupMenu) {
        return
      }
      menuBarItem.popupMenu.close()
    })
  }

  focusOut (event) {
    if (!this.desktopMode && event.relatedTarget) {
      // first check if we have relatedTarget is disconnected from the menubar domNode
      const relativePosition = this.domNode.compareDocumentPosition(
        event.relatedTarget
      )
      if (Node.DOCUMENT_POSITION_DISCONNECTED & relativePosition) {
        // disconnected means we left
        // because this.domNode is in shadowDom before and after of main dom elements is implementation dependent (unreliable)
        // compare the menu item (this.parentNode) to the event target instead
        const parentRelativePosition = this.parentNode.compareDocumentPosition(
          event.relatedTarget
        )
        if (Node.DOCUMENT_POSITION_FOLLOWING & parentRelativePosition) {
          // if target node the current node we're off the bottom
          this.setFocus()
          return
        }
        this.setFocusToLastMobile()
      }
    }
  }

  setFocusToLastMobile () {
    // level 1 and level 2 navigation divs both have a utility nav - select the active (visible) div, then the last visible link
    const utilityNavs = this.domNode.querySelectorAll('div.amp-utilityNav')
    var visibleUtilityNav = null
    for (var i = utilityNavs.length - 1; i >= 0; i--) {
      if (utilityNavs[i].offsetParent) {
        visibleUtilityNav = utilityNavs[i]
      }
    }
    if (visibleUtilityNav === null) return
    var focusable = visibleUtilityNav.querySelectorAll('a')
    for (i = focusable.length - 1; i >= 0; i--) {
      if (focusable[i].offsetParent) {
        focusable[i].focus()
        return
      }
    }
  }

  initBackClick (backClick) {
    this.backClick = backClick
  }
}
