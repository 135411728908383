import './disclaimer.less'
import Analytics from '../../SiteNav/analytics.js'
import jsCookie from 'js-cookie'

/*
 * Ameriprise Site Footer Disclaimer
 */
class AmeripriseFooterDisclaimer extends HTMLElement {
  connectedCallback () {
    this.shadow = this.attachShadow({ mode: 'open' })

    // TODO move/remove this functionality
    // this sets the Find An Advisor button href and analytics

    this.template = this.querySelector('#disclaimer-template')

    this.template &&
      this.template.content &&
      this.shadow.appendChild(this.template.content)

    // replace all urls with js-cookie value at end of path
    const ameripriseAdvisorsMatch = 'ameripriseadvisors.com'
    const queryParameterName = 'lastAmpAuthState'
    // Returns the new url with our new query parameter.
    function formatQueryStringParameter (uri, key, value) {
      if (uri) {
        var re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i')
        var separator =
          uri && uri.indexOf('?') && uri.indexOf('?') !== -1 ? '&' : '?'
        if (uri.match(re)) {
          return uri.replace(re, '$1' + key + '=' + value + '$2')
        } else {
          return uri + separator + key + '=' + value
        }
      }
    }
    // Gets the value for for lastAmpAuthState from the cookie.
    const getLastAmpAuthStateCookie = function () {
      if (window.location.hostname === 'localhost') {
        jsCookie.set('lastAmpAuthState', 'unrecognized')
      }
      const lastAmpAuthState = jsCookie.get('lastAmpAuthState')
      return lastAmpAuthState || 'unrecognized'
    }

    function getShadowLinks (theShadow) {
      const lastAmpAuthState = getLastAmpAuthStateCookie()
      const queryselector = "[href*='" + ameripriseAdvisorsMatch + "']"
      var links = theShadow.querySelectorAll(queryselector)
      links.forEach(link => {
        link.href = formatQueryStringParameter(
          link.href,
          queryParameterName,
          lastAmpAuthState
        )
      })
    }

    /* Footer disclosures and Social Network links Analytics */
    const footerDisclaimerContent = this.shadow.querySelector(
      '.amp-footerSocialAndDisclaimer'
    )
    const disclaimerLinks =
      footerDisclaimerContent &&
      footerDisclaimerContent.getElementsByTagName('a')
    disclaimerLinks &&
      disclaimerLinks.length > 0 &&
      disclaimerLinks.forEach(link => {
        link &&
          link.addEventListener('click', event => {
            const disclaimerMessage =
              event.currentTarget && event.currentTarget.innerText
            let type = 'footer'

            try {
              const disclaimerAnalytics = new Analytics()
              disclaimerAnalytics.ping(disclaimerMessage, type)
            } catch (e) {}
          })
      })
    getShadowLinks(this.shadow)

    this.nortonContainer = this.shadow.querySelector('.amp-nortonPopup')

    if (this.nortonContainer) {
      this.nortonContainer.addEventListener('click', event => {
        event.preventDefault()

        const popupName = 'Norton Secured Seal'
        const targetUrl = event.currentTarget.getAttribute('href') || '/'
        const params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=580,height=500,left=-1000,top=-1000`

        window.open(targetUrl, popupName, params)
      })
    }
  }
}

window.customElements.define(
  'ameriprise-footer-disclaimer',
  AmeripriseFooterDisclaimer
)
