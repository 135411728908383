import React from 'react'
import { createRoot } from 'react-dom/client'
import SocialMediaBar from './SocialMediaBar.jsx'

export default class SocialMediaBarElement extends HTMLElement {
  connectedCallback () {
    this.mountPoint = document.createElement('div')
    const imageSrcPath = this.getAttribute('data-image-src-path')
    const findAnAdvisorBtnText = this.getAttribute(
      'data-find-an-advisor-btn-text'
    )
    const findAnAdvisorBtnUrl = this.getAttribute(
      'data-find-an-advisor-btn-url'
    )

    const shadowRoot = this.attachShadow({ mode: 'open' })
    shadowRoot.appendChild(this.mountPoint)

    this.template = this.querySelector('#social-media-bar-template')
    if (this.template && this.template.content) {
      shadowRoot.appendChild(this.template.content)
    }

    const root = createRoot(this.mountPoint)

    // hide DOM during page load
    root.render(
      <div class="u-adel-reset">
        <div class="u-block" style={{ display: 'none' }}>
          <SocialMediaBar
            content={{
              imageSrcPath,
              findAnAdvisorBtnText,
              findAnAdvisorBtnUrl
            }}
          />
        </div>
      </div>
    )
  }
}
