import { getUser } from './../../../services'
import jsCookie from 'js-cookie'

import Analytics from '../../SiteNav/analytics.js'
import './utility.less'
import PersonalizedButton from './PersonalizedButton'
import Menubar from '../Navigation/menubar.js'

function IsLargeViewport() {
  return (
    (window.innerWidth * 16) /
      parseFloat(
        getComputedStyle(document.querySelector('html'))['font-size']
      ) >
    1024
  )
}

const USERSTATUS = {
  EXPIRED: 'expired',
  UNRECOGNIZED: 'unrecognized',
  ACTIVE: 'active'
}

const isDomainAllowed = () => {
  const host = window.location.hostname
  if (
    /localhost/.test(host) ||
    /ipp\.ameriprise\.com/.test(host) ||
    /ipp\.qa\.ameriprise\.com/.test(host) ||
    /qa\.ameripriseadvisors\.com/.test(host) ||
    /ameripriseadvisors\.com/.test(host) ||
    /newsroom\.ameriprise\.com/.test(host)
  ) {
    return false
  } else {
    return true
  }
}

// replace all urls with js-cookie value at end of path
const ameripriseAdvisorsMatch = 'ameripriseadvisors.com'
const queryParameterName = 'lastAmpAuthState'

// Returns the new url with our new query parameter.
function formatQueryStringParameter(uri, key, value) {
  if (uri) {
    var re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i')
    var separator =
      uri && uri.indexOf('?') && uri.indexOf('?') !== -1 ? '&' : '?'
    if (uri.match(re)) {
      return uri.replace(re, '$1' + key + '=' + value + '$2')
    } else {
      return uri + separator + key + '=' + value
    }
  }
}

// Gets the value for for lastAmpAuthState from the cookie.
const getLastAmpAuthStateCookie = function () {
  if (window.location.hostname === 'localhost') {
    jsCookie.set('lastAmpAuthState', 'unrecognized')
  }
  const lastAmpAuthState = jsCookie.get('lastAmpAuthState')
  return lastAmpAuthState || 'unrecognized'
}

function getShadowLinks(theShadow) {
  const lastAmpAuthState = getLastAmpAuthStateCookie()
  const queryselector = "[href*='" + ameripriseAdvisorsMatch + "']"
  var links = theShadow.querySelectorAll(queryselector)
  links.forEach(link => {
    link.href = formatQueryStringParameter(
      link.href,
      queryParameterName,
      lastAmpAuthState
    )
  })
}

/*
 * Ameriprise Site Header (Utility Nav)
 */
class AmeripriseHeader extends HTMLElement {
  connectedCallback() {
    let current = this
    this.shadow = this.attachShadow({ mode: 'open' })
    let currentShadow = this.shadow

    this.template = this.querySelector('#header-template')

    if (this.template && this.template.content) {
      this.shadow.appendChild(this.template.content)

      /*
       * LoadTemplateBasedOnUserStatus
       * This method loads the personalized header for the user
       */
      const LoadTemplateBasedOnUserStatus = userStatus => {
        switch (userStatus) {
        case USERSTATUS.ACTIVE:
          current.userTemplate = current.querySelector(
            '#header-active-template'
          )
          getShadowLinks(currentShadow)
          break
        case USERSTATUS.EXPIRED:
          current.userTemplate = current.querySelector(
            '#header-expired-template'
          )
          getShadowLinks(currentShadow)
          break
        default:
          current.userTemplate = current.querySelector(
            '#header-prospect-template'
          )
          getShadowLinks(currentShadow)
          break
        }

        if (userStatus === USERSTATUS.ACTIVE) {
          current.init()
        }

        if (
          userStatus === USERSTATUS.UNRECOGNIZED &&
          (isDomainAllowed() || window.location.hostname === 'localhost')
        ) {
          this.initPersonalizedProspect(current, currentShadow)
        }
        this.setupHamburgerClick(current, currentShadow)

        // SiteNav Header Analytics
        const siteNavHeaderLinks = current.utilityNav.getElementsByTagName('a')
        siteNavHeaderLinks &&
          siteNavHeaderLinks.length > 0 &&
          siteNavHeaderLinks.forEach(link => {
            link &&
              link.addEventListener('click', event => {
                const message = event.currentTarget.text
                const type = 'header'
                const headerClass = event.currentTarget.classList

                if (headerClass.contains('analytics-FindAnAdvisor')) {
                  try {
                    const customAnalytics = new Analytics()
                    customAnalytics.setSatelliteTrack('initiativeFindAnAdvisor')
                  } catch (e) {}
                }

                if (!headerClass.contains('amp-clientActiveProfile')) {
                  try {
                    const analytics = new Analytics()
                    analytics.ping(message, type)
                  } catch (e) {}
                }
              })
          })
      }

      /*
       * Please don't load the personalized header for the following sites,
       * AmeripriseAdvisors
       * NewsRoom
       * IPP Ameriprise
       * Localhost
       */
      const isSeriveCallAllowed = isDomainAllowed()
      if (isSeriveCallAllowed) {
        /*
         * GetUserStatus determine the status of the current user
         */
        const callValid =
          window.authState &&
          typeof window.authState.getUserStatus === 'function'

        callValid &&
          window.authState
            .getUserStatus()
            .then(currentAuthStatus => {
              if (currentAuthStatus) {
                LoadTemplateBasedOnUserStatus(currentAuthStatus)
              }
            })
            .catch(() => {
              LoadTemplateBasedOnUserStatus(USERSTATUS.UNRECOGNIZED)
            })
        if (!callValid) LoadTemplateBasedOnUserStatus(USERSTATUS.UNRECOGNIZED)
      } else {
        LoadTemplateBasedOnUserStatus(USERSTATUS.UNRECOGNIZED)
      }
    }
    return true
  }

  hideWelcome() {
    const clientWelcome = this.shadow.querySelector('.amp-clientWelcome')
    clientWelcome.classList.add('u-hidden')
  }

  setupHamburgerClick(current, currentShadow) {
    current.utilityNav = currentShadow.querySelector('.amp-utilityNav')
    current.utilityNav.appendChild(current.userTemplate.content)

    const hamburgerMenu = currentShadow.querySelector('.amp-hamburger')
    hamburgerMenu.addEventListener('click', event => {
      event.preventDefault()
      event.stopPropagation()
      window.dispatchEvent(
        new CustomEvent('menuclick', {
          detail: {
            currentTarget: event.currentTarget
          }
        })
      )
    })
  }

  async init() {
    await this.initClient()
  }

  async initClient() {
    // ignore ipp, advisors, newsroom
    const isSeriveCallAllowed = isDomainAllowed()
    if (!isSeriveCallAllowed) {
      this.hideWelcome()
      return
    }

    const { data, errors } = await getUser()

    if (errors && errors.length > 0) {
      this.hideWelcome()
    } else if (data && data.client && data.client.user) {
      const clientUser = data.client.user

      if (clientUser && clientUser.clFirstNm) {
        const clientActiveUserField = this.utilityNav.querySelector(
          '.amp-clientActiveProfile'
        )

        if (clientActiveUserField) {
          clientActiveUserField.innerHTML = clientUser.clFirstNm
        }
      }
    } else {
      this.hideWelcome()
    }

    return true
  }

  async initPersonalizedProspect(current, currentShadow) {
    let data = {}
    try {
      const response = await fetch('/relevanceapi/visitorinfo', {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(response => response.json())
        .then(data => data)

      // console.log(response)
      data =
        response.visitor.targetingData.advisorcollector?.extraData?.attributes
    } catch (error) {
      // console.log(error)
    }

    // Mock data for local host
    if (window.location.hostname === 'localhost') {
      data = {
        image:
          'https://cdn.ameriprisecontent.com/cds/alwp/echelonwealthpartners/echelon-wealth-partners_84741_355x266.jpg',
        name: 'Echelon Wealth Partners',
        tagline:
          'A private wealth advisory practice of Ameriprise Financial Services, LLC',
        url: 'https://www.qa.ameripriseadvisors.com/team/echelon-wealth-partners',
        phonenumber: '123-456-7890',
        email: 'echelonwealthpartners@ampf.com'
      }
    }

    if (data !== undefined && Object.keys(data).length > 0) {
      current.utilityNav = currentShadow.querySelector('.amp-utilityNav')
      current.utilityNav.appendChild(current.userTemplate.content)

      const navElt = document.createElement('nav')
      navElt.id = 'personalized-sticky-advisor'
      navElt.classList.add('amp-nav-level-one')
      let ulElt = document.createElement('ul')
      ulElt.setAttribute('role', 'menubar')

      navElt.appendChild(ulElt)
      const liElt = document.createElement('li')
      liElt.classList.add(
        'List-item',
        'u-margin0',
        'u-paddingTop0',
        'u-paddingBottom0'
      )
      liElt.setAttribute('role', 'menuitem')
      ulElt.appendChild(liElt)

      const personalizedButton = PersonalizedButton(data)
      liElt.appendChild(personalizedButton)

      let findAdvisorButton = currentShadow.querySelector(
        'a[data-qa="FindAdvisorButton"]'
      )
      if (findAdvisorButton) findAdvisorButton.replaceWith(navElt)
      let stickyBar = new Menubar(navElt.parentNode) // keyboard navigation
      stickyBar.init(
        document.querySelector('ameriprise-header'),
        'div li',
        'div li a'
      )
      stickyBar.setMode(IsLargeViewport())
    }

    getShadowLinks(currentShadow)
    return true
  }
}
window.customElements.define('ameriprise-header', AmeripriseHeader)
