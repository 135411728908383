import { track } from '@cwp/utils'
import { CONSTs } from './util.js'
/*
 * Ameriprise SiteNav Analytics
 * Example: To trigger the SiteNav analytics, we need CustomEvent.
 * @params(Message, Type)
 * Message: Name/text of the link
 * Type: Defines the element(Header, Footer, Nav)
 * const siteNavAnalytics = new Analytics()
 * siteNavAnalytics.ping(Message, Type)
 */
export default class Analytics {
  // eslint-disable-next-line no-useless-constructor
  constructor () { }

  setSatelliteTrack (message = '') {
    if (window._satellite && typeof window._satellite.track === 'function') {
      message && window._satellite.track(message)
    }
    return true
  }

  ping (message, pingSrcType = 'link', pingType = CONSTs.TYPE_ANALYTICS_CUSTOM_EVENT) {
    // first check to make sure its a valid message string
    if (!message || typeof message !== 'string' || message.length < 1) {
      track.warn('analytics.ping() received an invalid message. message must be a string.')
      return
    }
    // also check to make sure it is a valid ping source type string
    if (!pingSrcType || typeof pingSrcType !== 'string' || pingSrcType.length < 1) {
      track.warn('analytics.ping() received an invalid Ping Source Type. Ping Source Type must be a string.')
      return
    } else {
      // we force lowercase strings.
      pingSrcType = pingSrcType.toLowerCase()
    }
    // ping the analytics framework using the correct message delivery subsystem.
    // NOTE: Currently there is only one subsystem (firing a custom event) but
    // there are potential plans to implement an analytics API endpoint sometime in the future
    // so we designed this module to be easy to switch to that API endpoint when
    // it becomes available. Simply add another case to this switch statement that calls
    // a pingAPIendpoint() function.
    switch (pingType) {
    case CONSTs.TYPE_ANALYTICS_CUSTOM_EVENT:
      this.pingCustomEvent(message, pingSrcType, CONSTs.TYPE_ANALYTICS_CUSTOM_EVENT)
      break
    default:
      this.pingCustomEvent(message, pingSrcType, CONSTs.TYPE_ANALYTICS_CUSTOM_EVENT)
      break
    }
  }
  pingCustomEvent (MESSAGE, PING_SRC_TYPE, TYPE_ANALYTICS_CUSTOM_EVENT) {
    let activityEvent
    const detail = { detail: {} }
    detail.detail = { message: MESSAGE, type: PING_SRC_TYPE }
    try {
      activityEvent = new CustomEvent(TYPE_ANALYTICS_CUSTOM_EVENT, detail)
    } catch (err) {
      // CustomEvent is not supported in IE browsers, so we do it the old way for them.
      const bubbles = false
      const cancelable = false
      activityEvent = document.createEvent('CustomEvent')
      activityEvent.initCustomEvent(TYPE_ANALYTICS_CUSTOM_EVENT, bubbles, cancelable, detail)
    }
    document.body.dispatchEvent(activityEvent)
  }
}
