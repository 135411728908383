import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import './social-media-bar.css'
import jsCookie from 'js-cookie'
import Analytics from '../SiteNav/analytics.js'

export const SocialMediaBar = props => {
  const { content, testId } = props
  const { imageSrcPath, findAnAdvisorBtnText, findAnAdvisorBtnUrl } = content
  const [isFindAnAdvisorShown, setIsFindAnAdvisorShown] = useState(false)
  const [findAnAdvisorLink, setFindAnAdvisorLink] =
    useState(findAnAdvisorBtnUrl)

  // check if host is localhost or .ameriprise.com but is not newsroom
  const host = window.location.hostname
  const isAmeripriseDotCom =
    /localhost/.test(host) ||
    /^(?!.*\bnewsroom\b.*\.ameriprise\.com)(?=.*\.ameriprise\.com).*/.test(host)

  useEffect(() => {
    const userStatus = jsCookie.get('lastAmpAuthState') ?? 'unrecognized'

    // TODO: determine whether logic can be removed
    // force other domains to always display Find an Advisor to logged in and expired clients
    if (isAmeripriseDotCom) {
      const show = userStatus !== 'active' && userStatus !== 'expired'
      setIsFindAnAdvisorShown(show)
    } else {
      setIsFindAnAdvisorShown(true)
    }

    function formatQueryStringParameter(uri, key, value) {
      if (uri) {
        const re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i')
        const separator =
          uri.indexOf('?') && uri.indexOf('?') !== -1 ? '&' : '?'
        if (uri.match(re)) {
          return uri.replace(re, '$1' + key + '=' + value + '$2')
        }
        return uri + separator + key + '=' + value
      }
    }
    const link = formatQueryStringParameter(
      findAnAdvisorBtnUrl,
      'lastAmpAuthState',
      userStatus
    )
    setFindAnAdvisorLink(link)
  }, [findAnAdvisorBtnUrl, isAmeripriseDotCom])

  function handleClick(event) {
    // Add Analytics
    const disclaimerMessage =
      event.currentTarget && event.currentTarget.innerText
    try {
      if (disclaimerMessage === 'Find an Advisor') {
        const customAnalytics = new Analytics()
        customAnalytics.setSatelliteTrack('initiativeFindAnAdvisor')
      }
      const disclaimerAnalytics = new Analytics()
      disclaimerAnalytics.ping(disclaimerMessage, 'footer')
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <div className="u-adel-reset u-pt-hidden">
      <div className="SocialMediaBar u-paddingVert2gu u-block u-bgColorBrandMidnight">
        <div className="u-sizeConstrained Grid">
          <div
            className="u-sm-hidden u-md-size6of12 u-lg-size6of12 u-marginVert2gu amp-LogoOnly"
            style={{
              backgroundImage: `url(${imageSrcPath}/amp-icon-white.svg)`
            }}
          ></div>
          <div className="u-sm-sizeFull u-md-size2of12 u-lg-size2of12 u-md-textLeft u-lg-textCenter u-lg-paddingRight10gu">
            {isFindAnAdvisorShown && (
              <div className="amp-advisorButton u-sm-marginTop3gu u-flexJustifyEnd u-paddingVert2gu">
                <a
                  onClick={handleClick}
                  data-analytics="Find an Advisor"
                  data-qa="FindAdvisorButton"
                  className="Button Button--primary Button--reversed Button--small u-sm-block u-inline-block u-marginVert2gu u-textNoWrap analytics-FindAnAdvisor"
                  href={findAnAdvisorLink}
                  data-testid={testId ? `${testId}-find-an-advisor-btn` : null}
                  target="_blank"
                  rel="noreferrer"
                >
                  {findAnAdvisorBtnText}
                </a>
              </div>
            )}
          </div>
          <div className="u-paddingHoriz5gu u-sm-marginTop3gu u-sm-marginBottom3gu u-sm-sizeFull u-md-size4of12 u-lg-size4of12 u-flex u-flexJustifyBetween">
            <a
              onClick={handleClick}
              className="SocialMediaLink Link Link--reversed u-block u-marginVert1gu u-marginHoriz2gu u-padding2gu u-linkClean"
              href="https://www.linkedin.com/company/3032"
              rel="external noreferrer"
              target="_blank"
              data-testid={testId ? `${testId}-linkedin-link` : null}
            >
              <span
                aria-hidden="true"
                className="Icon u-textColorWhite u-textSizeMsrp6"
              >
                &#xeac9;
              </span>
              <span className="u-hiddenVisually">LinkedIn</span>
            </a>
            <a
              onClick={handleClick}
              className="SocialMediaLink Link Link--reversed u-block u-marginVert1gu u-marginHoriz2gu u-padding2gu u-linkClean"
              href="https://facebook.com/ameriprise"
              rel="external noreferrer"
              target="_blank"
              data-testid={testId ? `${testId}-facebook-link` : null}
            >
              <span
                aria-hidden="true"
                className="Icon u-textColorWhite u-textSizeMsrp6"
              >
                &#xea91;
              </span>
              <span className="u-hiddenVisually">Facebook</span>
            </a>
            <a
              onClick={handleClick}
              className="SocialMediaLink Link Link--reversed u-block u-marginVert1gu u-marginHoriz2gu u-padding2gu u-linkClean"
              href="https://youtube.com/Ameriprise"
              rel="external noreferrer"
              target="_blank"
              data-testid={testId ? `${testId}-youtube-link` : null}
            >
              <span
                aria-hidden="true"
                className="Icon u-textColorWhite u-textSizeMsrp6"
              >
                &#xea9d;
              </span>
              <span className="u-hiddenVisually">YouTube</span>
            </a>
            <a
              onClick={handleClick}
              className="SocialMediaLink Link Link--reversed u-block u-marginVert1gu u-marginHoriz2gu u-padding2gu u-linkClean"
              href="https://www.instagram.com/ameriprise/"
              rel="external noreferrer"
              target="_blank"
              data-testid={testId ? `${testId}-instagram-link` : null}
            >
              <img src={`${imageSrcPath}/instagram-icon.svg`} alt="" />
              <span className="u-hiddenVisually">Instagram</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

SocialMediaBar.propTypes = {
  content: PropTypes.shape({
    imageSrcPath: PropTypes.string.isRequired,
    findAnAdvisorBtnText: PropTypes.string.isRequired,
    findAnAdvisorBtnUrl: PropTypes.node.isRequired
  }),
  testId: PropTypes.string
}

SocialMediaBar.defaultProps = {
  content: {
    imageSrcPath:
      'https://www.ameriprise.com/webfiles/latest/assets/ampcom/images/',
    findAnAdvisorBtnText: 'Find an Advisor',
    findAnAdvisorBtnUrl: 'https://www.ameripriseadvisors.com/'
  }
}

export default SocialMediaBar
